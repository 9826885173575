/** AirportParameters */
export interface AirportParameters {
  /**
   * Typology of the airport. Possible values: ['MILITARY', 'CIVIL']
   * @example "CIVIL"
   */
  airport_type: AirportTypeEnum;
  /**
   * Size of the airport. This field is applicable just to CIVIL airports. Possible values: ['GENERAL AVIATION', 'SMALL', 'MEDIUM', 'LARGE']
   * @example "SMALL"
   */
  airport_size: AirportSizeEnum;
}

/** AirportSizeEnum */
export enum AirportSizeEnum {
  GENERALAVIATION = "GENERAL AVIATION",
  SMALL = "SMALL",
  MEDIUM = "MEDIUM",
  LARGE = "LARGE",
}

/** AirportTypeEnum */
export enum AirportTypeEnum {
  MILITARY = "MILITARY",
  CIVIL = "CIVIL",
}

/** AssetAssignmentInfoResponseApi */
export interface AssetAssignmentInfoResponseApi {
  /**
   * Id
   * Unique identifier for the asset assignment
   * @example "Asset Assignment 01"
   */
  id: string;
  /**
   * Asset Id
   * Asset id
   * @example "Asset 01"
   */
  asset_id: string;
  /**
   * Site Id
   * Site id assigned to the asset
   * @example "Site 01"
   */
  site_id: string;
}

/** AssetBasicResponse */
export interface AssetBasicResponse {
  /**
   * Name
   * Building name
   * @example "Data Center"
   */
  name: string;
  /**
   * Nickname
   * Short-hand ID of the asset. The length of the nickname field cannot be longer than 20 characters and it cannot contain white spaces.
   * @example "SEA-3"
   */
  nickname?: string | null;
  /**
   * City
   * city
   * @example "San Francisco"
   */
  city?: string | null;
  /**
   * State
   * state
   * @example "CA"
   */
  state?: string | null;
  /**
   * Country
   * country
   * @example "USA"
   */
  country?: string | null;
  /**
   * Street Address
   * Street address of the building
   * @example "1100 Second Ave"
   */
  street_address?: string | null;
  /**
   * Zip Code
   * Zip code of the building
   * @example "98101"
   */
  zip_code?: string | null;
  /**
   * Latitude
   * The latitude of the asset.
   * @example 43.23
   */
  latitude?: number | null;
  /**
   * Longitude
   * The longitude of the asset.
   * @example 44.12
   */
  longitude?: number | null;
  /**
   * Occ Area
   * Total occupied area [sq ft]
   * @example 20.5
   */
  occ_area?: number | null;
  /**
   * Total Building Population
   * Total building population
   * @example 230
   */
  total_building_population?: number | null;
  /**
   * N Floors
   * Total number of stories, including above and below ground
   * @example 4
   */
  n_floors?: number | null;
  /**
   * Gross Asset Value
   * Gross Asset Value
   * @example 43.2
   */
  gross_asset_value?: number | null;
  /**
   * Year Of Construction
   * year of construction
   * @example 2020
   */
  year_of_construction?: number | null;
  /**
   * Ownership
   * ownership
   * @example ["Owned","Leased","Owned and 3rd Party Operated","Leased and 3rd Party Operated"]
   */
  ownership?: OwnershipEnum[] | null;
  /**
   * Asset Status
   * Asset status of occupancy
   * @example ["In Use","In-Design","Under Construction","Prospective","Retired"]
   */
  asset_status?: AssetStatusEnum[] | null;
  /**
   * Asset status of occupancy
   * @default "In Use"
   * @example "In Use"
   */
  status?: AssetStatusEnum | null;
  /**
   * Business Group
   * Business specific groupings
   * @example "business-group-01"
   */
  business_group?: string | null;
  /**
   * Primary Use
   * The primary occupancy
   * @example ["Apartment"]
   */
  primary_use?: BuildingUseEnum[] | null;
  /**
   * Archived
   * Whether the asset is removed or not.
   * @example false
   */
  archived: boolean;
  /**
   * Replacement Cost
   * Cost (USD) to replace the building if it is a total loss
   * @example 1000000
   */
  replacement_cost?: number | null;
  /**
   * Id
   * Main key to identify each building document, cannot be repeated and should not be modified
   * @example "SEA03"
   */
  id: string;
  /**
   * Version
   * version number
   * @example "1"
   */
  version: string;
  /** Geo Location */
  geo_location: number[] | null;
}

/** AssetGroupsRequestApi */
export interface AssetGroupsRequestApi {
  /**
   * Asset Ids
   * List of asset ids (uuid)
   * @minItems 1
   * @example ["009548f6-aec4-42cf-acc0-cd792003ca06","125e7eb8-ab82-4261-ae90-1f43661f317a","08ea476e-7423-4330-82ea-3e822a362376"]
   */
  asset_ids: string[];
}

/** AssetGroupsResponseApi */
export interface AssetGroupsResponseApi {
  /**
   * Asset Id
   * Asset id
   * @example "009548f6-aec4-42cf-acc0-cd792003ca0"
   */
  asset_id: string;
  /** Groups */
  groups: GroupBasicResponse[];
}

/** AssetIdsAttributesRequestApi */
export interface AssetIdsAttributesRequestApi {
  /**
   * Attributes
   * List of valid asset attributes to retrieve
   * @example ["city","nickname"]
   */
  attributes?: string[] | null;
  /**
   * Asset Ids
   * List of asset ids (uuid)
   * @minItems 1
   * @example ["009548f6-aec4-42cf-acc0-cd792003ca06","125e7eb8-ab82-4261-ae90-1f43661f317a","08ea476e-7423-4330-82ea-3e822a362376"]
   */
  asset_ids: string[];
}

/** AssetSearchRequestApi */
export interface AssetSearchRequestApi {
  /**
   * Attributes
   * List of valid asset attributes to retrieve
   * @example ["city","nickname"]
   */
  attributes?: string[] | null;
  /**
   * Keyword
   * Keyword to search on in asset name, nickname, description, city and address
   * @example "San Francisco"
   */
  keyword?: string | null;
}

/** AssetStatusEnum */
export enum AssetStatusEnum {
  InUse = "In Use",
  Site = "Site",
  InDesign = "In-Design",
  UnderConstruction = "Under Construction",
  Prospective = "Prospective",
  Retired = "Retired",
  Test = "Test",
}

/** AssetsDetail */
export interface AssetsDetail {
  /**
   * Total Assets
   * Total number of assets of the group
   * @example 12
   */
  total_assets: number;
  /**
   * Total Population
   * Total population of the group
   * @example 20000
   */
  total_population: number;
  /**
   * Total Value
   * Total gross values of the assets of the group
   * @example 400
   */
  total_value: number;
  /**
   * Total Replacement Cost
   * Total replacement cost of the group's assets
   */
  total_replacement_cost: number;
  /**
   * Total Occupied Area
   * Total occupied area of the assets of the group
   * @example 200
   */
  total_occupied_area: number;
  /**
   * Ownership
   * Ownership of the assets of the group order from the most to the least common
   * @example ["Owned","Leased","Leased and 3rd Party Operated"]
   */
  ownership: OwnershipEnum[];
  /**
   * Primary Use
   * Primary usage of the assets of the group order from the most to the least common
   * @example ["Apartment","Office"]
   */
  primary_use: BuildingUseEnum[];
}

/** Body_add_resource_to_job_jobs__job_id__resources_post */
export interface BodyAddResourceToJobJobsJobIdResourcesPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_batch_create_assets_jobs_asset_batch_post */
export interface BodyBatchCreateAssetsJobsAssetBatchPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_batch_run_jobs_jobs_export_batch_post */
export interface BodyBatchRunJobsJobsExportBatchPost {
  /** Ref Ids */
  ref_ids: string[];
  parameters: ExportJobRequest;
}

/** Body_upload_asset_resource_assets__asset_id__resources_post */
export interface BodyUploadAssetResourceAssetsAssetIdResourcesPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_group_resource_groups__group_id__resources_post */
export interface BodyUploadGroupResourceGroupsGroupIdResourcesPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_resource_resources__post */
export interface BodyUploadResourceResourcesPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** BuildingCriticallityEnum */
export enum BuildingCriticallityEnum {
  Critical = "Critical",
  Important = "Important",
  Normal = "Normal",
  Low = "Low",
}

/** BuildingUseEnum */
export enum BuildingUseEnum {
  Apartment = "Apartment",
  DataCenter = "Data Center",
  Healthcare = "Healthcare",
  Hospitality = "Hospitality",
  Laboratory = "Laboratory",
  Office = "Office",
  Retail = "Retail",
  School = "School",
  Warehouse = "Warehouse",
  Residential = "Residential",
  Administrative = "Administrative",
  Academic = "Academic",
  SpecialLaboratory = "Special Laboratory",
  Parking = "Parking",
  EmergencyServices = "Emergency Services",
  Government = "Government",
  UtilitySubstation = "Utility Substation",
  Daycare = "Daycare",
  Library = "Library",
  Museum = "Museum",
  Fulfillment = "Fulfillment",
  Distribution = "Distribution",
  RD = "R&D",
  Dining = "Dining",
  Childcare = "Childcare",
  Gym = "Gym",
  Auditorium = "Auditorium",
  SportsVenue = "Sports Venue",
  Theater = "Theater",
  Factory = "Factory",
  Mill = "Mill",
  Custom = "Custom",
}

/** Business */
export interface Business {
  /**
   * Essential Flag
   * True/False indicator for whether or not building is essential. This will impact downtime
   * @example true
   */
  essential_flag: boolean;
  /**
   * Finance Method
   * Description of finance method
   * @example ["Pre-Arranged Credit","Insurance","Private Loans","SBA-Backed Loans"]
   */
  finance_method: FinanceMethodEnum[];
  /**
   * Insur Limit Ratio
   * Insurance limit (as fraction of replacement cost)
   * @example 0.7
   */
  insur_limit_ratio: number;
  /**
   * Loss Thresh Ratio
   * Loss threshold (as fraction of replacement cost) at which it makes financial sense to redesign building
   * @example 1
   */
  loss_thresh_ratio: number;
  /**
   * Available Fund Ratio
   * Available funds (as fraction of replacement cost) for immediate repairs
   * @example 100
   */
  available_fund_ratio: number;
  /**
   * Deductible Ratio
   * Deductible (as fraction of replacement cost)
   * @example 0.02
   */
  deductible_ratio: number;
}

/** Contents */
export interface Contents {
  /**
   * Hazmat Flag
   * True/False indicator for whether or not building is hazardous materials. This will impact repair cost
   * @example true
   */
  hazmat_flag: boolean;
}

/** CreateAssetRequest */
export interface CreateAssetRequest {
  /**
   * Name
   * Building name
   * @example "Data Center"
   */
  name: string;
  /**
   * Nickname
   * Short-hand ID of the asset. The length of the nickname field cannot be longer than 20 characters and it cannot contain white spaces.
   * @example "SEA-3"
   */
  nickname?: string | null;
  /**
   * City
   * city
   * @example "San Francisco"
   */
  city?: string | null;
  /**
   * State
   * state
   * @example "CA"
   */
  state?: string | null;
  /**
   * Country
   * country
   * @example "USA"
   */
  country?: string | null;
  /**
   * Street Address
   * Street address of the building
   * @example "1100 Second Ave"
   */
  street_address?: string | null;
  /**
   * Zip Code
   * Zip code of the building
   * @example "98101"
   */
  zip_code?: string | null;
  /**
   * Latitude
   * The latitude of the asset.
   * @example 43.23
   */
  latitude?: number | null;
  /**
   * Longitude
   * The longitude of the asset.
   * @example 44.12
   */
  longitude?: number | null;
  /**
   * Occ Area
   * Total occupied area [sq ft]
   * @example 20.5
   */
  occ_area?: number | null;
  /**
   * Total Building Population
   * Total building population
   * @example 230
   */
  total_building_population?: number | null;
  /**
   * N Floors
   * Total number of stories, including above and below ground
   * @example 4
   */
  n_floors?: number | null;
  /**
   * Gross Asset Value
   * Gross Asset Value
   * @example 43.2
   */
  gross_asset_value?: number | null;
  /**
   * Year Of Construction
   * year of construction
   * @example 2020
   */
  year_of_construction?: number | null;
  /**
   * Ownership
   * ownership
   * @example ["Owned","Leased","Owned and 3rd Party Operated","Leased and 3rd Party Operated"]
   */
  ownership?: OwnershipEnum[] | null;
  /**
   * Asset Status
   * Asset status of occupancy
   * @example ["In Use","In-Design","Under Construction","Prospective","Retired"]
   */
  asset_status?: AssetStatusEnum[] | null;
  /**
   * Asset status of occupancy
   * @default "In Use"
   * @example "In Use"
   */
  status?: AssetStatusEnum | null;
  /**
   * Business Group
   * Business specific groupings
   * @example "business-group-01"
   */
  business_group?: string | null;
  /**
   * Primary Use
   * The primary occupancy
   * @example ["Apartment"]
   */
  primary_use?: BuildingUseEnum[] | null;
  /**
   * Archived
   * Whether the asset is removed or not.
   * @example false
   */
  archived?: boolean | null;
  /**
   * Replacement Cost
   * Cost (USD) to replace the building if it is a total loss
   * @example 1000000
   */
  replacement_cost?: number | null;
  /**
   * Description
   * Description of the asset
   * @example "Description of the asset"
   */
  description?: string | null;
  /**
   * Floor Areas
   * Area of each floor [sq ft]
   * @example [10000.3,10000.3,10000.3]
   */
  floor_areas?: number[] | null;
  /**
   * Total Area
   * Total area of building [sq ft]
   * @example 60000.5
   */
  total_area?: number | null;
  /**
   * Area Correction Factor
   * Average annual vacant area [sq ft]
   * @example 20.2
   */
  area_correction_factor?: number | null;
  /**
   * Footprint
   * Area of ground floor [sq ft]
   * @example 20.5
   */
  footprint?: number | null;
  /**
   * N Above
   * Number of above-ground stories
   * @example 2
   */
  n_above?: number | null;
  /**
   * N Basement
   * Number of below-ground stories
   * @example 2
   */
  n_basement?: number | null;
  /**
   * Total Height
   * Total height above-ground (ft)
   * @example 400
   */
  total_height?: number | null;
  /**
   * Floor Heights
   * Height of every story
   * @example "[2.5, 3.0, 4.5]"
   */
  floor_heights?: number[] | null;
  /**
   * Replacement Time
   * Number of days to replace the building if it is a total loss
   * @example 365
   */
  replacement_time?: number | null;
  /**
   * Floor Population
   * Number of occupants on each floor
   * @example "[20, 22, 24]"
   */
  floor_population?: number[] | null;
  /**
   * Secondary Use
   * The secondary occupancy
   * @example ["Healthcare"]
   */
  secondary_use?: BuildingUseEnum[] | null;
  /**
   * Tertiary Use
   * The tertiary occupancy
   * @example ["Office"]
   */
  tertiary_use?: BuildingUseEnum[] | null;
  /**
   * Quaternary Use
   * The quaternary occupancy
   * @example ["Library"]
   */
  quaternary_use?: BuildingUseEnum[] | null;
  /**
   * Pc Prim Use
   * Percent of total floor area that is primary occupancy
   * @example 60
   */
  pc_prim_use?: number | null;
  /**
   * Pc Sec Use
   * Percent of total floor area that is secondary occupancy
   * @example 20
   */
  pc_sec_use?: number | null;
  /**
   * Pc Ter Use
   * Percent of total floor area that is tertiary occupancy
   * @example 10
   */
  pc_ter_use?: number | null;
  /**
   * Pc Qua Use
   * Percent of total floor area that is quaternary occupancy
   * @example 10
   */
  pc_qua_use?: number | null;
  /**
   * Backup Power
   * Hours of backup power
   * @example "8"
   */
  backup_power?: number | null;
  /**
   * Backup Water
   * Hours of backup water
   * @example "8"
   */
  backup_water?: number | null;
  /**
   * Eco
   * Factor to correct for the fact that the building is not 100% populated at all times
   * @example "8.5"
   */
  eco?: number | null;
  /**
   * Design Year
   * design year
   * @example 2020
   */
  design_year?: number | null;
  /**
   * Design Code
   * Building code and year
   * @example "B-123 2020"
   */
  design_code?: string | null;
  /**
   * Lease End Date
   * lease end date
   * @example 2020
   */
  lease_end_date?: number | null;
  /**
   * Structural Retrofit
   * structural retrofit
   * @example ["No","Full","Partial"]
   */
  structural_retrofit?: StructuralRetrofitEnum[] | null;
  /**
   * Structural Retrofit Year
   * structural retrofit year
   * @example 2021
   */
  structural_retrofit_year?: number | null;
  /**
   * Structural Retrofit Code
   * structural retrofit code
   * @example "B"
   */
  structural_retrofit_code?: string | null;
  /**
   * Internal Refurbishment
   * internal refurbishment
   * @example ["No","Full","Partial"]
   */
  internal_refurbishment?: StructuralRetrofitEnum[] | null;
  /**
   * Refurbishment Year
   * refurbishment year
   * @example 2021
   */
  refurbishment_year?: number | null;
  /**
   * Building Criticallity
   * building criticallity
   * @example ["Critical","Important","Normal","Low"]
   */
  building_criticallity?: BuildingCriticallityEnum[] | null;
  /**
   * Monthly Revenue
   * Net revenue from sales, rent, etc.
   * @example 5000
   */
  monthly_revenue?: number | null;
  /**
   * Business Interruption Value
   * Insured value for biz interruption
   * @example 5000
   */
  business_interruption_value?: number | null;
  /**
   * Building Inventory
   * Type of inventory sold to customers
   * @example "B"
   */
  building_inventory?: string | null;
  /**
   * Special Contents
   * Special contents, not sold to customers
   * @example ["Artwork","Museum Collections","Research Specimens","Data/Archives","Lab Equipment","Special Machinery","Animals","Custom Field"]
   */
  special_contents?: SpecialContentsEnum[] | null;
  /**
   * Inventory Value
   * inventory value
   * @example 5000
   */
  inventory_value?: number | null;
  /**
   * Special Equipment Value
   * Machinery/special equipment value
   * @example 5000
   */
  special_equipment_value?: number | null;
  /**
   * Hazardous Contents Flag
   * Hazardous contents flag
   * @example ["Yes","No"]
   */
  hazardous_contents_flag?: string[] | null;
  /**
   * Structural Drawings
   * Hazardous contents flag
   * @example ["No","Poor Quality","Good Quality"]
   */
  structural_drawings?: StructuralDrawingsEnum[] | null;
  /**
   * Site Visit
   * Hazardous contents flag
   * @example ["No","Virtual","Sidewalk","Full"]
   */
  site_visit?: SiteVisitEnum[] | null;
  /**
   * Total Insured Value
   * Total insured value
   * @example 1200
   */
  total_insured_value?: number | null;
  /**
   * Footprint Geometry
   * Site boundary of the asset.
   * @example {"crs":{"properties":{"name":"urn:ogc:def:crs:OGC:1.3:CRS84"},"type":"name"},"features":[{"geometry":{"coordinates":[[[-80.08117896211209,26.736416497181196],[-80.08117896211209,26.736656538979233],[-80.0808378500833,26.736656538979233],[-80.0808378500833,26.736416497181196],[-80.08117896211209,26.736416497181196]]],"type":"Polygon"},"properties":{},"type":"Feature"}],"name":"Square","type":"FeatureCollection"}
   */
  footprint_geometry?: object | null;
  /**
   * Floor Length
   * The floor length in ft. Length of list must be equal to the number of floor.
   * @example [200.3]
   */
  floor_length?: number[] | null;
  /**
   * Floor Width
   * The floor width in ft. Length of list must be equal to the number of floor.
   * @example [150.3]
   */
  floor_width?: number[] | null;
}

/** CreateAssetResponse */
export interface CreateAssetResponse {
  /**
   * Name
   * Building name
   * @example "Data Center"
   */
  name: string;
  /**
   * Nickname
   * Short-hand ID of the asset. The length of the nickname field cannot be longer than 20 characters and it cannot contain white spaces.
   * @example "SEA-3"
   */
  nickname?: string | null;
  /**
   * City
   * city
   * @example "San Francisco"
   */
  city?: string | null;
  /**
   * State
   * state
   * @example "CA"
   */
  state?: string | null;
  /**
   * Country
   * country
   * @example "USA"
   */
  country?: string | null;
  /**
   * Street Address
   * Street address of the building
   * @example "1100 Second Ave"
   */
  street_address?: string | null;
  /**
   * Zip Code
   * Zip code of the building
   * @example "98101"
   */
  zip_code?: string | null;
  /**
   * Latitude
   * The latitude of the asset.
   * @example 43.23
   */
  latitude?: number | null;
  /**
   * Longitude
   * The longitude of the asset.
   * @example 44.12
   */
  longitude?: number | null;
  /**
   * Occ Area
   * Total occupied area [sq ft]
   * @example 20.5
   */
  occ_area?: number | null;
  /**
   * Total Building Population
   * Total building population
   * @example 230
   */
  total_building_population?: number | null;
  /**
   * N Floors
   * Total number of stories, including above and below ground
   * @example 4
   */
  n_floors?: number | null;
  /**
   * Gross Asset Value
   * Gross Asset Value
   * @example 43.2
   */
  gross_asset_value?: number | null;
  /**
   * Year Of Construction
   * year of construction
   * @example 2020
   */
  year_of_construction?: number | null;
  /**
   * Ownership
   * ownership
   * @example ["Owned","Leased","Owned and 3rd Party Operated","Leased and 3rd Party Operated"]
   */
  ownership?: OwnershipEnum[] | null;
  /**
   * Asset Status
   * Asset status of occupancy
   * @example ["In Use","In-Design","Under Construction","Prospective","Retired"]
   */
  asset_status?: AssetStatusEnum[] | null;
  /**
   * Asset status of occupancy
   * @default "In Use"
   * @example "In Use"
   */
  status?: AssetStatusEnum | null;
  /**
   * Business Group
   * Business specific groupings
   * @example "business-group-01"
   */
  business_group?: string | null;
  /**
   * Primary Use
   * The primary occupancy
   * @example ["Apartment"]
   */
  primary_use?: BuildingUseEnum[] | null;
  /**
   * Archived
   * Whether the asset is removed or not.
   * @example false
   */
  archived: boolean;
  /**
   * Replacement Cost
   * Cost (USD) to replace the building if it is a total loss
   * @example 1000000
   */
  replacement_cost?: number | null;
  /**
   * Description
   * Description of the asset
   * @example "Description of the asset"
   */
  description?: string | null;
  /**
   * Floor Areas
   * Area of each floor [sq ft]
   * @example [10000.3,10000.3,10000.3]
   */
  floor_areas?: number[] | null;
  /**
   * Total Area
   * Total area of building [sq ft]
   * @example 60000.5
   */
  total_area?: number | null;
  /**
   * Area Correction Factor
   * Average annual vacant area [sq ft]
   * @example 20.2
   */
  area_correction_factor?: number | null;
  /**
   * Footprint
   * Area of ground floor [sq ft]
   * @example 20.5
   */
  footprint?: number | null;
  /**
   * N Above
   * Number of above-ground stories
   * @example 2
   */
  n_above?: number | null;
  /**
   * N Basement
   * Number of below-ground stories
   * @example 2
   */
  n_basement?: number | null;
  /**
   * Total Height
   * Total height above-ground (ft)
   * @example 400
   */
  total_height?: number | null;
  /**
   * Floor Heights
   * Height of every story
   * @example "[2.5, 3.0, 4.5]"
   */
  floor_heights?: number[] | null;
  /**
   * Replacement Time
   * Number of days to replace the building if it is a total loss
   * @example 365
   */
  replacement_time?: number | null;
  /**
   * Floor Population
   * Number of occupants on each floor
   * @example "[20, 22, 24]"
   */
  floor_population?: number[] | null;
  /**
   * Secondary Use
   * The secondary occupancy
   * @example ["Healthcare"]
   */
  secondary_use?: BuildingUseEnum[] | null;
  /**
   * Tertiary Use
   * The tertiary occupancy
   * @example ["Office"]
   */
  tertiary_use?: BuildingUseEnum[] | null;
  /**
   * Quaternary Use
   * The quaternary occupancy
   * @example ["Library"]
   */
  quaternary_use?: BuildingUseEnum[] | null;
  /**
   * Pc Prim Use
   * Percent of total floor area that is primary occupancy
   * @example 60
   */
  pc_prim_use?: number | null;
  /**
   * Pc Sec Use
   * Percent of total floor area that is secondary occupancy
   * @example 20
   */
  pc_sec_use?: number | null;
  /**
   * Pc Ter Use
   * Percent of total floor area that is tertiary occupancy
   * @example 10
   */
  pc_ter_use?: number | null;
  /**
   * Pc Qua Use
   * Percent of total floor area that is quaternary occupancy
   * @example 10
   */
  pc_qua_use?: number | null;
  /**
   * Backup Power
   * Hours of backup power
   * @example "8"
   */
  backup_power?: number | null;
  /**
   * Backup Water
   * Hours of backup water
   * @example "8"
   */
  backup_water?: number | null;
  /**
   * Eco
   * Factor to correct for the fact that the building is not 100% populated at all times
   * @example "8.5"
   */
  eco?: number | null;
  /**
   * Design Year
   * design year
   * @example 2020
   */
  design_year?: number | null;
  /**
   * Design Code
   * Building code and year
   * @example "B-123 2020"
   */
  design_code?: string | null;
  /**
   * Lease End Date
   * lease end date
   * @example 2020
   */
  lease_end_date?: number | null;
  /**
   * Structural Retrofit
   * structural retrofit
   * @example ["No","Full","Partial"]
   */
  structural_retrofit?: StructuralRetrofitEnum[] | null;
  /**
   * Structural Retrofit Year
   * structural retrofit year
   * @example 2021
   */
  structural_retrofit_year?: number | null;
  /**
   * Structural Retrofit Code
   * structural retrofit code
   * @example "B"
   */
  structural_retrofit_code?: string | null;
  /**
   * Internal Refurbishment
   * internal refurbishment
   * @example ["No","Full","Partial"]
   */
  internal_refurbishment?: StructuralRetrofitEnum[] | null;
  /**
   * Refurbishment Year
   * refurbishment year
   * @example 2021
   */
  refurbishment_year?: number | null;
  /**
   * Building Criticallity
   * building criticallity
   * @example ["Critical","Important","Normal","Low"]
   */
  building_criticallity?: BuildingCriticallityEnum[] | null;
  /**
   * Monthly Revenue
   * Net revenue from sales, rent, etc.
   * @example 5000
   */
  monthly_revenue?: number | null;
  /**
   * Business Interruption Value
   * Insured value for biz interruption
   * @example 5000
   */
  business_interruption_value?: number | null;
  /**
   * Building Inventory
   * Type of inventory sold to customers
   * @example "B"
   */
  building_inventory?: string | null;
  /**
   * Special Contents
   * Special contents, not sold to customers
   * @example ["Artwork","Museum Collections","Research Specimens","Data/Archives","Lab Equipment","Special Machinery","Animals","Custom Field"]
   */
  special_contents?: SpecialContentsEnum[] | null;
  /**
   * Inventory Value
   * inventory value
   * @example 5000
   */
  inventory_value?: number | null;
  /**
   * Special Equipment Value
   * Machinery/special equipment value
   * @example 5000
   */
  special_equipment_value?: number | null;
  /**
   * Hazardous Contents Flag
   * Hazardous contents flag
   * @example ["Yes","No"]
   */
  hazardous_contents_flag?: string[] | null;
  /**
   * Structural Drawings
   * Hazardous contents flag
   * @example ["No","Poor Quality","Good Quality"]
   */
  structural_drawings?: StructuralDrawingsEnum[] | null;
  /**
   * Site Visit
   * Hazardous contents flag
   * @example ["No","Virtual","Sidewalk","Full"]
   */
  site_visit?: SiteVisitEnum[] | null;
  /**
   * Total Insured Value
   * Total insured value
   * @example 1200
   */
  total_insured_value?: number | null;
  /**
   * Footprint Geometry
   * Site boundary of the asset.
   * @example {"crs":{"properties":{"name":"urn:ogc:def:crs:OGC:1.3:CRS84"},"type":"name"},"features":[{"geometry":{"coordinates":[[[-80.08117896211209,26.736416497181196],[-80.08117896211209,26.736656538979233],[-80.0808378500833,26.736656538979233],[-80.0808378500833,26.736416497181196],[-80.08117896211209,26.736416497181196]]],"type":"Polygon"},"properties":{},"type":"Feature"}],"name":"Square","type":"FeatureCollection"}
   */
  footprint_geometry?: object | null;
  /**
   * Floor Length
   * The floor length in ft. Length of list must be equal to the number of floor.
   * @example [200.3]
   */
  floor_length?: number[] | null;
  /**
   * Floor Width
   * The floor width in ft. Length of list must be equal to the number of floor.
   * @example [150.3]
   */
  floor_width?: number[] | null;
  /**
   * Id
   * Main key to identify each building document, cannot be repeated and should not be modified
   * @example "SEA03"
   */
  id: string;
  /**
   * Organization Id
   * Id of the organization this asset belongs to.
   * @example "123-123-123"
   */
  organization_id: string;
  /**
   * Version
   * version number
   * @example "1"
   */
  version: string;
  /**
   * Created By
   * The user who created the asset
   * @example "jane.doe@arup.com"
   */
  created_by?: string | null;
  /**
   * Created At
   * The create date and time of the asset
   * @example "2022-06-01T06:41:07.689488+00:00"
   */
  created_at?: string | null;
  /** Geo Location */
  geo_location: number[] | null;
}

/** CreateGroupRequest */
export interface CreateGroupRequest {
  /**
   * Name
   * Group Name
   * @example "Group 01"
   */
  name: string;
  /**
   * Description
   * My group description
   * @example "This is a group record"
   */
  description?: string | null;
  /**
   * Group type
   * @example "TOWN"
   */
  type: GroupType;
}

/** CreateRiskRequestApi */
export interface CreateRiskRequestApi {
  repair: Repair;
  wind: Wind;
  general: General;
  impeding_factors: ImpedingFactors;
  contents: Contents;
  seismic: Seismic;
  business: Business;
}

/** CreateRiskResponseApi */
export interface CreateRiskResponseApi {
  repair: Repair;
  wind: Wind;
  general: General;
  impeding_factors: ImpedingFactors;
  contents: Contents;
  seismic: Seismic;
  business: Business;
  /**
   * Id
   * Main key to identify Risk parameter
   * @example "RISK-123"
   */
  id: string;
  /**
   * Asset Id
   * Main key to identify each building document, cannot be repeated and should not be modified
   * @example "SEA03"
   */
  asset_id: string;
  /**
   * Asset Version
   * asset version number
   * @example "1"
   */
  asset_version: string;
  /**
   * Version
   * risk rating parameters version number
   * @example "1"
   */
  version: string;
}

/** CreateSiteInfoResponseApi */
export interface CreateSiteInfoResponseApi {
  /**
   * Name
   * Name of the site.
   * @example "Amsterdam Highway"
   */
  name?: string | null;
  /**
   * Typology of the site. Possible values: ['BUILDING', 'AIRPORT', 'ROAD', 'RAIL', 'SITE']
   * @example "ROAD"
   */
  type: SiteType;
  /**
   * Location
   * Latitude and Longitude of site [lat, lon]
   * @example [43.234,44.12]
   */
  location: number[];
  /**
   * Shape type of the site. Possible values: ['MULTILINE', 'LINE', 'POLYGON']
   * @example "LINE"
   */
  shape_type: SiteShapeType;
  /**
   * Shape
   * Coordinates of the vertices of the site having the shape shape_type (enum).
   * @example "[[1,2],[2,3]]"
   */
  shape: number[][];
  /**
   * Parameters
   * Parameters for ROAD and AIRPORT SiteType.
   * @example {"intersections":true,"local_road":false,"on_off_ramps":false}
   */
  parameters?: RoadParameters | AirportParameters | null;
  /**
   * Id
   * Unique id for the site
   * @example "uuid"
   */
  id: string;
  /**
   * Assigned To Assets
   * List of assets ids to which the Site is assigned
   * @example "['asset1, asset2']"
   */
  assigned_to_assets?: string[] | null;
  /**
   * Created At
   * When the site was created
   * @format date-time
   * @example "07/02/2022"
   */
  created_at: string;
  /**
   * Updated At
   * When the site was created
   * @example "08/02/2022"
   */
  updated_at?: string | null;
}

/** ExportJobRequest */
export interface ExportJobRequest {
  /**
   * The MIME type of the resource.
   * @example "application/pdf"
   */
  content_type: MIMEType;
  /**
   * Path
   * Optional path to append to the default url (https://HOST/REF_TYPE/REF_ID/) to the resource.
   * @default ""
   * @example "/preview"
   */
  path?: string | null;
  /**
   * Url
   * The URL to use instead of the default url (https://HOST/REF_TYPE/REF_ID/)
   * @example "https://web.iris.arup.com/assets/ASSET_123"
   */
  url?: string | null;
}

/** FinanceMethodEnum */
export enum FinanceMethodEnum {
  PreArrangedCredit = "Pre-Arranged Credit",
  Insurance = "Insurance",
  PrivateLoans = "Private Loans",
  SBABackedLoans = "SBA-Backed Loans",
}

/** General */
export interface General {
  /**
   * Beta M
   * Uncertainty in modeling EDPs for earthquake shaking
   * @example 0.5
   */
  beta_m: number;
  /**
   * Time Fact
   * Multiplier for financial losses based on year
   * @example 1.07
   */
  time_fact: number;
  /**
   * Loc Fact
   * Multiplier for financial losses based on location
   * @example 1
   */
  loc_fact: number;
}

/** GroupAssignmentResponse */
export interface GroupAssignmentResponse {
  /**
   * Id
   * Unique identifier for the group assignment
   * @example "Group Assignment 01"
   */
  id: string;
  /**
   * Added By
   * Added By
   * @example "user@arup.com"
   */
  added_by: string;
}

/** GroupBasicResponse */
export interface GroupBasicResponse {
  /**
   * Name
   * Group Name
   * @example "Group 01"
   */
  name: string;
  /**
   * Description
   * My group description
   * @example "This is a group record"
   */
  description?: string | null;
  /**
   * Group type
   * @example "TOWN"
   */
  type: GroupType;
  /**
   * Id
   * Unique identifier for the group
   * @example "Group 01"
   */
  id: string;
}

/** GroupResponse */
export interface GroupResponse {
  /**
   * Name
   * Group Name
   * @example "Group 01"
   */
  name: string;
  /**
   * Description
   * My group description
   * @example "This is a group record"
   */
  description?: string | null;
  /**
   * Group type
   * @example "TOWN"
   */
  type: GroupType;
  /**
   * Id
   * Unique identifier for the group
   * @example "Group 01"
   */
  id: string;
  /**
   * Created At
   * When the group was created
   * @example "07/02/2022"
   */
  created_at?: string | null;
  /**
   * Created By
   * User who created the group
   * @example "user@arup.com"
   */
  created_by?: string | null;
  /**
   * Updated At
   * When the group was updated
   * @example "08/02/2022"
   */
  updated_at?: string | null;
  /**
   * Updated By
   * User who updated the group
   * @example "user@arup.com"
   */
  updated_by?: string | null;
  /** Group metadata */
  metadata?: AssetsDetail | null;
}

/** GroupType */
export enum GroupType {
  BUSINESS_GROUP = "BUSINESS_GROUP",
  CAMPUS = "CAMPUS",
  CITY = "CITY",
  COUNTY = "COUNTY",
  MARKET = "MARKET",
  METRO = "METRO",
  NEIGHBORHOOD = "NEIGHBORHOOD",
  PORTFOLIO = "PORTFOLIO",
  PRECINCT = "PRECINCT",
  PROJECT = "PROJECT",
  REGION = "REGION",
  SITE = "SITE",
  TOWN = "TOWN",
  VILLAGE = "VILLAGE",
  ZONE = "ZONE",
}

/** GroupUpdateRequest */
export interface GroupUpdateRequest {
  /**
   * Name
   * Group Name
   * @example "Group 01"
   */
  name?: string | null;
  /**
   * Description
   * My group description
   * @example "This is a group record"
   */
  description?: string | null;
  /**
   * Group type
   * @example "TOWN"
   */
  type?: GroupType | null;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** ImpedingFactors */
export interface ImpedingFactors {
  /**
   * Evacuation Flag
   * True/False indicator for whether or not occupants will evacuate the building in advance of event (e.g. hurricane). This will impact downtime
   * @example true
   */
  evacuation_flag: boolean;
  /**
   * Longlead
   * Distribution (type and COV) for long lead time
   * @example {"beta":0.18,"distribution":"Lognormal","threshold":0.01}
   */
  longlead: object;
  /**
   * Inspection Delay
   * Inspection impeding delay
   * @example {"beta":0.54,"distribution":"Lognormal","theta":1}
   */
  inspection_delay: object;
  /**
   * Waters Recede Delay
   * Waters recede impeding delay
   * @example {"beta":0.54,"distribution":"Lognormal","theta":1}
   */
  waters_recede_delay: object;
  /**
   * Engineer Mobilization Delay Seismic
   * Engineer mobilization impeding delay for seismic
   * @example {"beta":0.4,"distribution":"Lognormal","theta":42}
   */
  engineer_mobilization_delay_seismic: object;
  /**
   * Engineer Mobilization Delay Wind
   * Engineer mobilization impeding delay for wind
   * @example {"beta":0.4,"distribution":"Lognormal","theta":42}
   */
  engineer_mobilization_delay_wind: object;
  /**
   * Engineer Mobilization Delay Flood
   * Engineer mobilization impeding delay for flood
   * @example {"beta":0.4,"distribution":"Lognormal","theta":42}
   */
  engineer_mobilization_delay_flood: object;
  /**
   * Contractor Mobilization Delay Seismic
   * Contractor mobilization impeding delay for seismic
   * @example {"distribution":"Normal","rc1":{"sigma_by_seq":[38.5,17.5,48.300000000000004,39.9,25.900000000000002,72.8,22.400000000000002,22.400000000000002],"theta_by_seq":[100.8,50.4,91.7,85.39999999999999,60.199999999999996,130.9,57.39999999999999,57.39999999999999]},"rc23":{"sigma_by_seq":[50.4,52.5,118.29999999999998,115.5,23.8,93.10000000000001,113.39999999999999,23.8],"theta_by_seq":[150.5,122.5,145.6,135.1,79.10000000000001,192.5,121.10000000000001,79.10000000000001]}}
   */
  contractor_mobilization_delay_seismic: object;
  /**
   * Contractor Mobilization Delay Wind
   * Contractor mobilization impeding delay for wind
   * @example {"distribution":"Normal","rc1":{"sigma_by_seq":[38.5,17.5,48.300000000000004,39.9,25.900000000000002,72.8,22.400000000000002,22.400000000000002],"theta_by_seq":[100.8,50.4,91.7,85.39999999999999,60.199999999999996,130.9,57.39999999999999,57.39999999999999]},"rc23":{"sigma_by_seq":[50.4,52.5,118.29999999999998,115.5,23.8,93.10000000000001,113.39999999999999,23.8],"theta_by_seq":[150.5,122.5,145.6,135.1,79.10000000000001,192.5,121.10000000000001,79.10000000000001]}}
   */
  contractor_mobilization_delay_wind: object;
  /**
   * Contractor Mobilization Delay Flood
   * Contractor mobilization impeding delay for flood
   * @example {"distribution":"Normal","rc1":{"sigma_by_seq":[38.5,17.5,48.300000000000004,39.9,25.900000000000002,72.8,22.400000000000002,22.400000000000002],"theta_by_seq":[100.8,50.4,91.7,85.39999999999999,60.199999999999996,130.9,57.39999999999999,57.39999999999999]},"rc23":{"sigma_by_seq":[50.4,52.5,118.29999999999998,115.5,23.8,93.10000000000001,113.39999999999999,23.8],"theta_by_seq":[150.5,122.5,145.6,135.1,79.10000000000001,192.5,121.10000000000001,79.10000000000001]}}
   */
  contractor_mobilization_delay_flood: object;
  /**
   * Financing Delay
   * Financing impeding delay
   * @example {"beta":1.11,"distribution":"Lognormal","theta":42}
   */
  financing_delay: object;
  /**
   * Restoration Delay
   * Restoration impeding delay
   * @example {"beta":1.11,"distribution":"Lognormal","theta":42}
   */
  restoration_delay: object;
  /**
   * Permit Delay Seismic
   * Permit impeding delay for seismic
   * @example {"distribution":"Lognormal","rc1":{"beta":0.86,"theta":7},"rc23":{"beta":0.32,"theta":56}}
   */
  permit_delay_seismic: object;
  /**
   * Permit Delay Wind
   * Permit impeding delay for wind
   * @example {"distribution":"Lognormal","rc1":{"beta":0.86,"theta":7},"rc23":{"beta":0.32,"theta":56}}
   */
  permit_delay_wind: object;
  /**
   * Permit Delay Flood
   * Permit impeding delay for flood
   * @example {"distribution":"Lognormal","rc1":{"beta":0.86,"theta":7},"rc23":{"beta":0.32,"theta":56}}
   */
  permit_delay_flood: object;
}

/** Job */
export interface Job {
  /**
   * Id
   * The unique identifier of the job.
   * @example "uuid"
   */
  id?: string;
  /**
   * The type of job to be executed, e.g. EXPORT.
   * @example "EXPORT"
   */
  type: JobType;
  /**
   * The type of object the job is linked to.
   * @example "ASSET"
   */
  ref_type: ServiceReferenceTypeEnum;
  /**
   * Ref Id
   * Reference to the parent object requestion the job, e.g. ASSESSMENT.
   * @example "ASSESSMENT_123"
   */
  ref_id: string;
  /**
   * The status of the job.
   * @default "RUNNING"
   * @example "RUNNING"
   */
  status?: StatusEnum | null;
  /**
   * Created At
   * Timestamp in UTC when the job was created.
   * @example "2022-07-01T10:00:00.000000+00:00"
   */
  created_at?: string | null;
  /**
   * Created By
   * Who submitted the job.
   * @example "user@arup.com"
   */
  created_by: string;
  /**
   * Updated At
   * Timestamp in UTC when the job was updated.
   * @example "2022-07-02T10:00:00.000000+00:00"
   */
  updated_at?: string | null;
  /**
   * Details
   * Message explaining more about the job, e.g. error message if it failed.
   * @example "Job failed because of reason XYZ."
   */
  details?: string | null;
}

/** JobResponse */
export interface JobResponse {
  /**
   * Id
   * The unique identifier of the job.
   * @example "uuid"
   */
  id?: string;
  /**
   * Details
   * Message explaining more about the job, e.g. error message if it failed.
   * @example "Job failed because of reason XYZ."
   */
  details?: string | null;
  /**
   * The type of job to be executed, e.g. EXPORT.
   * @example "EXPORT"
   */
  type: JobType;
  /**
   * The type of object the job is linked to.
   * @example "ASSET"
   */
  ref_type: ServiceReferenceTypeEnum;
  /**
   * Ref Id
   * Reference to the parent object requestion the job, e.g. ASSESSMENT.
   * @example "ASSESSMENT_123"
   */
  ref_id: string;
  /**
   * The status of the job.
   * @default "RUNNING"
   * @example "RUNNING"
   */
  status?: StatusEnum | null;
  /**
   * Created At
   * Timestamp in UTC when the job was created.
   * @example "2022-07-01T10:00:00.000000+00:00"
   */
  created_at?: string | null;
  /**
   * Created By
   * Who submitted the job.
   * @example "user@arup.com"
   */
  created_by: string;
  /**
   * Updated At
   * Timestamp in UTC when the job was updated.
   * @example "2022-07-02T10:00:00.000000+00:00"
   */
  updated_at?: string | null;
  /**
   * Resources
   * The resources ouput from the job.
   * @example []
   */
  resources?: ResourceInfo[] | null;
}

/** JobType */
export enum JobType {
  EXPORT = "EXPORT",
  PRESCREENING = "PRESCREENING",
  IMPORT = "IMPORT",
}

/** JobUpdateRequest */
export interface JobUpdateRequest {
  /**
   * The status of the job.
   * @example "RUNNING"
   */
  status?: StatusEnum | null;
  /**
   * Details
   * Message explaining more about the job, e.g. error message if it failed.
   * @example "Job failed because of reason XYZ."
   */
  details?: string | null;
}

/** MIMEType */
export enum MIMEType {
  ImagePng = "image/png",
  ApplicationPdf = "application/pdf",
}

/** MissileEnvEnum */
export enum MissileEnvEnum {
  High = "High",
  Medium = "Medium",
  Low = "Low",
}

/** OwnershipEnum */
export enum OwnershipEnum {
  Owned = "Owned",
  Leased = "Leased",
  OwnedAnd3rdPartyOperated = "Owned and 3rd Party Operated",
  LeasedAnd3rdPartyOperated = "Leased and 3rd Party Operated",
}

/** PaginatedAsset */
export interface PaginatedAsset {
  /**
   * Results
   * Page of results requested
   */
  results: CreateAssetResponse[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   * @example "a12vsf"
   */
  cursor?: string | null;
}

/** PaginatedAssetAttributes */
export interface PaginatedAssetAttributes {
  /**
   * Results
   * Page of results requested
   */
  results: object[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   * @example "a12vsf"
   */
  cursor?: string | null;
}

/** PaginatedAssetBasic */
export interface PaginatedAssetBasic {
  /**
   * Results
   * Page of results requested
   */
  results: AssetBasicResponse[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   * @example "a12vsf"
   */
  cursor?: string | null;
}

/** PaginatedJobInfo */
export interface PaginatedJobInfo {
  /**
   * Results
   * Page of results requested
   */
  results: Job[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   * @example "a12vsf"
   */
  cursor?: string | null;
}

/** PatchAssetRequest */
export interface PatchAssetRequest {
  /**
   * Name
   * Building name
   * @example "Data Center"
   */
  name?: string | null;
  /**
   * Nickname
   * Short-hand ID of the asset. The length of the nickname field cannot be longer than 20 characters and it cannot contain white spaces.
   * @example "SEA-3"
   */
  nickname?: string | null;
  /**
   * City
   * city
   * @example "San Francisco"
   */
  city?: string | null;
  /**
   * State
   * state
   * @example "CA"
   */
  state?: string | null;
  /**
   * Country
   * country
   * @example "USA"
   */
  country?: string | null;
  /**
   * Street Address
   * Street address of the building
   * @example "1100 Second Ave"
   */
  street_address?: string | null;
  /**
   * Zip Code
   * Zip code of the building
   * @example "98101"
   */
  zip_code?: string | null;
  /**
   * Latitude
   * The latitude of the asset.
   * @example 43.23
   */
  latitude?: number | null;
  /**
   * Longitude
   * The longitude of the asset.
   * @example 44.12
   */
  longitude?: number | null;
  /**
   * Occ Area
   * Total occupied area [sq ft]
   * @example 20.5
   */
  occ_area?: number | null;
  /**
   * Total Building Population
   * Total building population
   * @example 230
   */
  total_building_population?: number | null;
  /**
   * N Floors
   * Total number of stories, including above and below ground
   * @example 4
   */
  n_floors?: number | null;
  /**
   * Gross Asset Value
   * Gross Asset Value
   * @example 43.2
   */
  gross_asset_value?: number | null;
  /**
   * Year Of Construction
   * year of construction
   * @example 2020
   */
  year_of_construction?: number | null;
  /**
   * Ownership
   * ownership
   * @example ["Owned","Leased","Owned and 3rd Party Operated","Leased and 3rd Party Operated"]
   */
  ownership?: OwnershipEnum[] | null;
  /**
   * Asset Status
   * Asset status of occupancy
   * @example ["In Use","In-Design","Under Construction","Prospective","Retired"]
   */
  asset_status?: AssetStatusEnum[] | null;
  /**
   * Asset status of occupancy
   * @example "In Use"
   */
  status?: AssetStatusEnum | null;
  /**
   * Business Group
   * Business specific groupings
   * @example "business-group-01"
   */
  business_group?: string | null;
  /**
   * Primary Use
   * The primary occupancy
   * @example ["Apartment"]
   */
  primary_use?: BuildingUseEnum[] | null;
  /** Archived */
  archived?: boolean | null;
  /**
   * Replacement Cost
   * Cost (USD) to replace the building if it is a total loss
   * @example 1000000
   */
  replacement_cost?: number | null;
  /**
   * Description
   * Description of the asset
   * @example "Description of the asset"
   */
  description?: string | null;
  /**
   * Floor Areas
   * Area of each floor [sq ft]
   * @example [10000.3,10000.3,10000.3]
   */
  floor_areas?: number[] | null;
  /**
   * Total Area
   * Total area of building [sq ft]
   * @example 60000.5
   */
  total_area?: number | null;
  /**
   * Area Correction Factor
   * Average annual vacant area [sq ft]
   * @example 20.2
   */
  area_correction_factor?: number | null;
  /**
   * Footprint
   * Area of ground floor [sq ft]
   * @example 20.5
   */
  footprint?: number | null;
  /**
   * N Above
   * Number of above-ground stories
   * @example 2
   */
  n_above?: number | null;
  /**
   * N Basement
   * Number of below-ground stories
   * @example 2
   */
  n_basement?: number | null;
  /**
   * Total Height
   * Total height above-ground (ft)
   * @example 400
   */
  total_height?: number | null;
  /**
   * Floor Heights
   * Height of every story
   * @example "[2.5, 3.0, 4.5]"
   */
  floor_heights?: number[] | null;
  /**
   * Replacement Time
   * Number of days to replace the building if it is a total loss
   * @example 365
   */
  replacement_time?: number | null;
  /**
   * Floor Population
   * Number of occupants on each floor
   * @example "[20, 22, 24]"
   */
  floor_population?: number[] | null;
  /**
   * Secondary Use
   * The secondary occupancy
   * @example ["Healthcare"]
   */
  secondary_use?: BuildingUseEnum[] | null;
  /**
   * Tertiary Use
   * The tertiary occupancy
   * @example ["Office"]
   */
  tertiary_use?: BuildingUseEnum[] | null;
  /**
   * Quaternary Use
   * The quaternary occupancy
   * @example ["Library"]
   */
  quaternary_use?: BuildingUseEnum[] | null;
  /**
   * Pc Prim Use
   * Percent of total floor area that is primary occupancy
   * @example 60
   */
  pc_prim_use?: number | null;
  /**
   * Pc Sec Use
   * Percent of total floor area that is secondary occupancy
   * @example 20
   */
  pc_sec_use?: number | null;
  /**
   * Pc Ter Use
   * Percent of total floor area that is tertiary occupancy
   * @example 10
   */
  pc_ter_use?: number | null;
  /**
   * Pc Qua Use
   * Percent of total floor area that is quaternary occupancy
   * @example 10
   */
  pc_qua_use?: number | null;
  /**
   * Backup Power
   * Hours of backup power
   * @example "8"
   */
  backup_power?: number | null;
  /**
   * Backup Water
   * Hours of backup water
   * @example "8"
   */
  backup_water?: number | null;
  /**
   * Eco
   * Factor to correct for the fact that the building is not 100% populated at all times
   * @example "8.5"
   */
  eco?: number | null;
  /**
   * Design Year
   * design year
   * @example 2020
   */
  design_year?: number | null;
  /**
   * Design Code
   * Building code and year
   * @example "B-123 2020"
   */
  design_code?: string | null;
  /**
   * Lease End Date
   * lease end date
   * @example 2020
   */
  lease_end_date?: number | null;
  /**
   * Structural Retrofit
   * structural retrofit
   * @example ["No","Full","Partial"]
   */
  structural_retrofit?: StructuralRetrofitEnum[] | null;
  /**
   * Structural Retrofit Year
   * structural retrofit year
   * @example 2021
   */
  structural_retrofit_year?: number | null;
  /**
   * Structural Retrofit Code
   * structural retrofit code
   * @example "B"
   */
  structural_retrofit_code?: string | null;
  /**
   * Internal Refurbishment
   * internal refurbishment
   * @example ["No","Full","Partial"]
   */
  internal_refurbishment?: StructuralRetrofitEnum[] | null;
  /**
   * Refurbishment Year
   * refurbishment year
   * @example 2021
   */
  refurbishment_year?: number | null;
  /**
   * Building Criticallity
   * building criticallity
   * @example ["Critical","Important","Normal","Low"]
   */
  building_criticallity?: BuildingCriticallityEnum[] | null;
  /**
   * Monthly Revenue
   * Net revenue from sales, rent, etc.
   * @example 5000
   */
  monthly_revenue?: number | null;
  /**
   * Business Interruption Value
   * Insured value for biz interruption
   * @example 5000
   */
  business_interruption_value?: number | null;
  /**
   * Building Inventory
   * Type of inventory sold to customers
   * @example "B"
   */
  building_inventory?: string | null;
  /**
   * Special Contents
   * Special contents, not sold to customers
   * @example ["Artwork","Museum Collections","Research Specimens","Data/Archives","Lab Equipment","Special Machinery","Animals","Custom Field"]
   */
  special_contents?: SpecialContentsEnum[] | null;
  /**
   * Inventory Value
   * inventory value
   * @example 5000
   */
  inventory_value?: number | null;
  /**
   * Special Equipment Value
   * Machinery/special equipment value
   * @example 5000
   */
  special_equipment_value?: number | null;
  /**
   * Hazardous Contents Flag
   * Hazardous contents flag
   * @example ["Yes","No"]
   */
  hazardous_contents_flag?: string[] | null;
  /**
   * Structural Drawings
   * Hazardous contents flag
   * @example ["No","Poor Quality","Good Quality"]
   */
  structural_drawings?: StructuralDrawingsEnum[] | null;
  /**
   * Site Visit
   * Hazardous contents flag
   * @example ["No","Virtual","Sidewalk","Full"]
   */
  site_visit?: SiteVisitEnum[] | null;
  /**
   * Total Insured Value
   * Total insured value
   * @example 1200
   */
  total_insured_value?: number | null;
  /**
   * Footprint Geometry
   * Site boundary of the asset.
   * @example {"crs":{"properties":{"name":"urn:ogc:def:crs:OGC:1.3:CRS84"},"type":"name"},"features":[{"geometry":{"coordinates":[[[-80.08117896211209,26.736416497181196],[-80.08117896211209,26.736656538979233],[-80.0808378500833,26.736656538979233],[-80.0808378500833,26.736416497181196],[-80.08117896211209,26.736416497181196]]],"type":"Polygon"},"properties":{},"type":"Feature"}],"name":"Square","type":"FeatureCollection"}
   */
  footprint_geometry?: object | null;
  /**
   * Floor Length
   * The floor length in ft. Length of list must be equal to the number of floor.
   * @example [200.3]
   */
  floor_length?: number[] | null;
  /**
   * Floor Width
   * The floor width in ft. Length of list must be equal to the number of floor.
   * @example [150.3]
   */
  floor_width?: number[] | null;
}

/** Repair */
export interface Repair {
  /**
   * Repair Class Fragility
   * Fragility for repair class
   * @example {"beta":0.4,"distribution":"Lognormal","theta":0.05}
   */
  repair_class_fragility: object;
  /**
   * Max Workers By Sequence
   * Maximum number of workers for each repair sequence
   * @example [[15,15,9,9,6,6,15],[30,30,18,18,12,12,30],[45,45,27,27,18,18,45]]
   */
  max_workers_by_sequence: number[][];
  /**
   * Max Workers Per Building
   * Function that describes the maximum number of workers that can be in the building, depending on the total square footage
   * @example {"mean":28,"minimum":20,"sigma":9.083,"slope":0.00028,"x_cutoff":80}
   */
  max_workers_per_building: object;
  /**
   * Nwork Perfloor Divider
   * Number of workers per floor divider for repair sequences A and G
   * @example [1000,1000,1000,1000,1000,1000,1000]
   */
  nwork_perfloor_divider: number[];
  /**
   * Max Workers Per Struct Divider
   * Maximum number of workers per structural divider
   * @example 500
   */
  max_workers_per_struct_divider: number;
  /**
   * Nworkers Recommended Mean
   * Mean number of workers per component for repair sequences B, C, D, E, and F
   * @example [2,2,2,3,3,2,2]
   */
  nworkers_recommended_mean: number[];
  /**
   * Nworkers Recomended Mean Struct
   * Mean number of workers per structural comopnent
   * @example 2
   */
  nworkers_recomended_mean_struct: number;
  /**
   * Workers Capacity
   * Distribution (type and COV) for worker capacity
   * @example {"beta":0.2,"distribution":"Lognormal"}
   */
  workers_capacity: object;
  /**
   * N Sequences
   * Total number of repair sequences
   * @example 8
   */
  n_sequences: number;
  /**
   * N Nonstruc Sequences
   * Total number of non-structural repair sequences
   * @example 7
   */
  n_nonstruc_sequences: number;
  /**
   * N Repair Goals
   * Total number of repair goals
   * @example 3
   */
  n_repair_goals: number;
}

/** ResourceDownloadInfo */
export interface ResourceDownloadInfo {
  /**
   * Id
   * Unique identifier (uuid) for the resource.
   * @example "b9cf0be6-4057-45b8-8e38-a23b5fee1941"
   */
  id: string;
  /**
   * File Name
   * Name of the file.
   * @example "report"
   */
  file_name: string;
  /**
   * Content Type
   * MIME type of the file.
   * @example "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
   */
  content_type: string;
  /**
   * File Extension
   * Extension of the file.
   * @example "docx"
   */
  file_extension: string;
  /**
   * Created By
   * User who uploaded the file.
   * @example "user@arup.com"
   */
  created_by: string;
  /**
   * Presigned Url
   * S3 presigned url for downloading the resource.
   * @example "..."
   */
  presigned_url: string;
}

/** ResourceInfo */
export interface ResourceInfo {
  /**
   * Id
   * Unique identifier (uuid) for the resource.
   * @example "b9cf0be6-4057-45b8-8e38-a23b5fee1941"
   */
  id: string;
  /**
   * File Name
   * Name of the file.
   * @example "report"
   */
  file_name: string;
  /**
   * Content Type
   * MIME type of the file.
   * @example "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
   */
  content_type: string;
  /**
   * File Extension
   * Extension of the file.
   * @example "docx"
   */
  file_extension: string;
  /**
   * Created By
   * User who uploaded the file.
   * @example "user@arup.com"
   */
  created_by: string;
  /**
   * Object Id
   * Id (uuid) of the object (asset/ group of assets) to which the resource is assigned to.
   * @example "user@arup.com"
   */
  object_id: string;
  /**
   * Type of the object to which the resource is assigned to. Possible values: ASSET, GROUP, JOB
   * @example "user@arup.com"
   */
  object_type: ServiceReferenceTypeEnum;
  /**
   * Description
   * Brief description of the file
   * @example "Inspection report ..."
   */
  description?: string | null;
  /**
   * Created At
   * When the file was uploaded.
   * @example "2022-06-01T06:41:07.689488+00:00"
   */
  created_at?: string | null;
  /**
   * Updated At
   * When the new version of the file was uploaded.
   * @example "2022-06-01T06:41:07.689488+00:00"
   */
  updated_at?: string | null;
  /**
   * Updated By
   * User who uploaded the new version of file.
   * @example "user@arup.com"
   */
  updated_by?: string | null;
}

/** RoadParameters */
export interface RoadParameters {
  /**
   * Intersections
   * True if the road has intersections. This parameter relies on the analyst's judgment.
   * @example true
   */
  intersections: boolean;
  /**
   * On Off Ramps
   * True if the road has on-off ramps. This parameter relies on the analyst's judgment.
   * @example true
   */
  on_off_ramps: boolean;
  /**
   * Local Road
   * True if the road is a local road. This parameter relies on the analyst's judgment.
   * @example true
   */
  local_road: boolean;
}

/** Seismic */
export interface Seismic {
  /**
   * Primary Type
   * Name of the primary lateral force-resisting type
   * @example "W1"
   */
  primary_type: string;
  /**
   * Secondary Type
   * Name of the secondary of orthogonal lateral force-resisting system
   * @example "W1A"
   */
  secondary_type: string;
  /**
   * Fema Score
   * Score from FEMA P-154
   * @example 2.21
   */
  fema_score: number;
  /**
   * Fema Code
   * FEMA P-154 benchmark code status
   * @example ["Pre-code","Moderate","Benchmark"]
   */
  fema_code: string[];
  /**
   * Fema Year
   * Year used for FEMA P-154 benchmark designation
   * @example 2021
   */
  fema_year: number;
  /**
   * Sdc
   * Seismic design category
   * @example "A"
   */
  sdc: string;
  /**
   * Liquefaction
   * Liquefaction
   * @example "Low"
   */
  liquefaction: string;
  /**
   * Site Class
   * Soil site class
   * @example "D"
   */
  site_class: string;
  /**
   * Norm Strength
   * Normalized strength of lateral system in x- and y-directions
   * @example [1.23,2.31,4.55]
   */
  norm_strength: number[];
  /**
   * T
   * Fundamental structural period of the building
   * @example 0.406677114
   */
  T: number;
  /**
   * Yield Drift
   * Interstory drift at which yield occurs in the x- and y-directions
   * @example [1.23,2.31,4.55]
   */
  yield_drift: number[];
  /**
   * Building Brittle
   * Boolean (True / False) that identifies if the building is brittle or not
   * @example true
   */
  building_brittle: boolean;
  /**
   * Collapse Fragility
   * This contains theta and beta that defines a lognormal collapse fragility
   * @example {"beta":3.21,"theta":2.23}
   */
  collapse_fragility: object;
  /**
   * Torsion Factor
   * Factor to amplify EDPs due to torsional irregularities
   * @example 1.21
   */
  torsion_factor: number;
  /**
   * Collapse Extent
   * Collapse extent. Value bounded between 0 and 1.
   * @example 0.55
   */
  collapse_extent?: number | null;
}

/** ServiceReferenceTypeEnum */
export enum ServiceReferenceTypeEnum {
  ASSET = "ASSET",
  GROUP = "GROUP",
  JOB = "JOB",
}

/** SimpleJobResponse */
export interface SimpleJobResponse {
  /**
   * Id
   * The unique identifier of the job.
   * @example "uuid"
   */
  id: string;
  /**
   * Details
   * Message explaining more about the job, e.g. error message if it failed.
   * @example "The job was submitted."
   */
  details: string;
}

/** SiteCentroidRequest */
export interface SiteCentroidRequest {
  /**
   * Street Address
   * Street address of the building
   * @example "1100 Second Ave"
   */
  street_address: string;
  /**
   * Zip Code
   * Zip code of the building
   * @example "98101"
   */
  zip_code?: string | null;
  /**
   * City
   * city
   * @example "San Francisco"
   */
  city?: string | null;
  /**
   * State
   * state
   * @example "CA"
   */
  state?: string | null;
  /**
   * Country
   * country
   * @example "USA"
   */
  country?: string | null;
}

/** SiteCoordinates */
export interface SiteCoordinates {
  /**
   * Latitude
   * Latitude of the site
   * @example 43.234
   */
  latitude?: number | null;
  /**
   * Longitude
   * Longitude of the site
   * @example 44.12
   */
  longitude?: number | null;
}

/** SiteFootprintResponse */
export interface SiteFootprintResponse {
  /**
   * Footprint Geometry
   * Footprint of the asset/site
   * @example {"crs":{"properties":{"name":"urn:ogc:def:crs:OGC:1.3:CRS84"},"type":"name"},"features":[{"geometry":{"coordinates":[[[-80.08117896211209,26.736416497181196],[-80.08117896211209,26.736656538979233],[-80.0808378500833,26.736656538979233],[-80.0808378500833,26.736416497181196],[-80.08117896211209,26.736416497181196]]],"type":"Polygon"},"properties":{},"type":"Feature"}],"name":"Square","type":"FeatureCollection"}
   */
  footprint_geometry?: object | null;
}

/** SiteInfo */
export interface SiteInfo {
  /**
   * Name
   * Name of the site.
   * @example "Amsterdam Highway"
   */
  name?: string | null;
  /**
   * Typology of the site. Possible values: ['BUILDING', 'AIRPORT', 'ROAD', 'RAIL', 'SITE']
   * @example "ROAD"
   */
  type: SiteType;
  /**
   * Location
   * Latitude and Longitude of site [lat, lon]
   * @example [43.234,44.12]
   */
  location: number[];
  /**
   * Shape type of the site. Possible values: ['MULTILINE', 'LINE', 'POLYGON']
   * @example "LINE"
   */
  shape_type: SiteShapeType;
  /**
   * Shape
   * Coordinates of the vertices of the site having the shape shape_type (enum).
   * @example "[[1,2],[2,3]]"
   */
  shape: number[][];
  /**
   * Parameters
   * Parameters for ROAD and AIRPORT SiteType.
   * @example {"intersections":true,"local_road":false,"on_off_ramps":false}
   */
  parameters?: RoadParameters | AirportParameters | null;
}

/** SiteShapeType */
export enum SiteShapeType {
  MULTILINE = "MULTILINE",
  LINE = "LINE",
  POLYGON = "POLYGON",
}

/** SiteType */
export enum SiteType {
  BUILDING = "BUILDING",
  AIRPORT = "AIRPORT",
  ROAD = "ROAD",
  RAIL = "RAIL",
  SITE = "SITE",
}

/** SiteVisitEnum */
export enum SiteVisitEnum {
  No = "No",
  Virtual = "Virtual",
  Sidewalk = "Sidewalk",
  Full = "Full",
}

/** SpecialContentsEnum */
export enum SpecialContentsEnum {
  Artwork = "Artwork",
  MuseumCollections = "Museum Collections",
  ResearchSpecimens = "Research Specimens",
  DataArchives = "Data/Archives",
  LabEquipment = "Lab Equipment",
  SpecialMachinery = "Special Machinery",
  Animals = "Animals",
  CustomField = "Custom Field",
}

/** StatusEnum */
export enum StatusEnum {
  CREATED = "CREATED",
  IN_PROGRESS = "IN_PROGRESS",
  RUNNING = "RUNNING",
  PUBLISHED = "PUBLISHED",
  FAILED = "FAILED",
  COMPLETED = "COMPLETED",
}

/** StructuralDrawingsEnum */
export enum StructuralDrawingsEnum {
  No = "No",
  PoorQuality = "Poor Quality",
  GoodQuality = "Good Quality",
}

/** StructuralRetrofitEnum */
export enum StructuralRetrofitEnum {
  No = "No",
  Full = "Full",
  Partial = "Partial",
}

/** TopUsesResponse */
export interface TopUsesResponse {
  /**
   * The primary use of the asset.
   * @example "Apartment"
   */
  primary_use: BuildingUseEnum;
  /**
   * Count
   * The number of assets of this primary use.
   * @example 10
   */
  count: number;
}

/** UpdateSiteInfoRequestApi */
export interface UpdateSiteInfoRequestApi {
  /**
   * Name
   * New name of the site.
   * @example "Amsterdam Airport"
   */
  name?: string | null;
  /**
   * Location
   * New Latitude and Longitude of site [lat, lon]
   * @example [43.234,44.12]
   */
  location?: number[] | null;
  /**
   * Shape
   * New coordinates of the vertices of the site having the shape shape_type (enum).
   * @example "[[1,2],[2,3]]"
   */
  shape?: number[][] | null;
  /**
   * Parameters
   * New parameters for ROAD or AIRPORT SiteType. This field is required only for ROAD and AIRPORT SiteType.
   * @example {"intersections":true,"local_road":false,"on_off_ramps":false}
   */
  parameters?: RoadParameters | AirportParameters | null;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

/** Wind */
export interface Wind {
  /**
   * Design Speed
   * Wind speed (3-s gust) that the building was designed for
   * @example 12.21
   */
  design_speed: number;
  /**
   * Load Factor
   * Load factor considered in wind design
   * @example 19.21
   */
  load_factor: number;
  /**
   * Roof Load
   * Expected gravity load on roof. This is used to calculate uplift pressure during the wind event
   * @example 9.21
   */
  roof_load: number;
  /**
   * Design Terrain Category
   * Exposure category for design, as defined in ASCE 7
   * @example "C"
   */
  design_terrain_category: string;
  /**
   * Missile Env
   * Low / Medium / High categorization of surrounding missiles for each of four faces around building
   * @maxItems 4
   * @minItems 3
   * @example ["High","Medium","Low"]
   */
  missile_env: MissileEnvEnum[];
  /**
   * Orientation
   * Orientation of the building relative to North
   * @example 6.21
   */
  orientation: number;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === "number" ? value : `${value}`
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string"
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${
        queryString ? `?${queryString}` : ""
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  assets = {
    /**
     * No description
     *
     * @tags assets
     * @name GetPaginatedAssets
     * @summary Get Paginated Assets
     * @request GET:/assets/
     * @secure
     */
    getPaginatedAssets: (
      query?: {
        /** Cursor */
        cursor?: string | null;
        /**
         * Limit
         * @default 10
         */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedAssetBasic, HTTPValidationError>({
        path: `/assets/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Create an Asset. The only required field is the `name` of the asset.
     *
     * @tags assets
     * @name CreateAsset
     * @summary Create Asset
     * @request POST:/assets/
     * @secure
     */
    createAsset: (data: CreateAssetRequest, params: RequestParams = {}) =>
      this.request<CreateAssetResponse, HTTPValidationError>({
        path: `/assets/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name SearchAssets
     * @summary Search Assets
     * @request POST:/assets/search
     * @secure
     */
    searchAssets: (
      data: AssetSearchRequestApi | null,
      query?: {
        /** Cursor */
        cursor?: string | null;
        /** Limit */
        limit?: number | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedAssetAttributes, HTTPValidationError>({
        path: `/assets/search`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name SearchAssetsByIds
     * @summary Search Assets By Ids
     * @request POST:/assets/search/ids
     * @secure
     */
    searchAssetsByIds: (
      data: AssetIdsAttributesRequestApi | null,
      params: RequestParams = {}
    ) =>
      this.request<object[], HTTPValidationError>({
        path: `/assets/search/ids`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Edit an Asset. The only required field is the `name` of the asset.
     *
     * @tags assets
     * @name UpdateAsset
     * @summary Update Asset
     * @request PATCH:/assets/{asset_id}
     * @secure
     */
    updateAsset: (
      assetId: string,
      data: PatchAssetRequest,
      params: RequestParams = {}
    ) =>
      this.request<CreateAssetResponse, HTTPValidationError>({
        path: `/assets/${assetId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name GetAsset
     * @summary Get Asset
     * @request GET:/assets/{asset_id}
     * @secure
     */
    getAsset: (
      assetId: string,
      query?: {
        /** Version Id */
        version_id?: string | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<CreateAssetResponse, HTTPValidationError>({
        path: `/assets/${assetId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name DeleteAsset
     * @summary Delete Asset
     * @request DELETE:/assets/{asset_id}
     * @secure
     */
    deleteAsset: (assetId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/assets/${assetId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name GetGroupsForAssets
     * @summary Get Groups For Assets
     * @request POST:/assets/details/groups
     * @secure
     */
    getGroupsForAssets: (
      data: AssetGroupsRequestApi,
      params: RequestParams = {}
    ) =>
      this.request<AssetGroupsResponseApi[], HTTPValidationError>({
        path: `/assets/details/groups`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets
     * @name GetAllAssetVersions
     * @summary Get All Asset Versions
     * @request GET:/assets/{asset_id}/versions
     * @secure
     */
    getAllAssetVersions: (
      assetId: string,
      query?: {
        /** Cursor */
        cursor?: string | null;
        /**
         * Limit
         * @default 10
         */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedAsset, HTTPValidationError>({
        path: `/assets/${assetId}/versions`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/risk-parameters
     * @name CreateRiskParameters
     * @summary Create Risk Parameters
     * @request POST:/assets/{asset_id}/risk-parameters
     * @deprecated
     * @secure
     */
    createRiskParameters: (
      assetId: string,
      data: CreateRiskRequestApi,
      params: RequestParams = {}
    ) =>
      this.request<CreateRiskResponseApi, HTTPValidationError>({
        path: `/assets/${assetId}/risk-parameters`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/risk-parameters
     * @name GetRiskParameters
     * @summary Get Risk Parameters
     * @request GET:/assets/{asset_id}/risk-parameters
     * @deprecated
     * @secure
     */
    getRiskParameters: (
      assetId: string,
      query?: {
        /** Asset Version */
        asset_version?: string | null;
        /** Version */
        version?: string | null;
        /**
         * Deprecated
         * @default true
         */
        deprecated?: any;
      },
      params: RequestParams = {}
    ) =>
      this.request<CreateRiskResponseApi, HTTPValidationError>({
        path: `/assets/${assetId}/risk-parameters`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/sites
     * @name AssignSiteToAsset
     * @summary Assign Site To Asset
     * @request POST:/assets/{asset_id}/site/{site_id}
     * @secure
     */
    assignSiteToAsset: (
      siteId: string,
      assetId: string,
      params: RequestParams = {}
    ) =>
      this.request<AssetAssignmentInfoResponseApi, HTTPValidationError>({
        path: `/assets/${assetId}/site/${siteId}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/sites
     * @name RemoveSiteAssignmentFromAsset
     * @summary Remove Site Assignment From Asset
     * @request DELETE:/assets/{asset_id}/site/{site_id}
     * @secure
     */
    removeSiteAssignmentFromAsset: (
      assetId: string,
      siteId: string,
      params: RequestParams = {}
    ) =>
      this.request<boolean, HTTPValidationError>({
        path: `/assets/${assetId}/site/${siteId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/sites
     * @name GetSitesForAsset
     * @summary Get Sites For Asset
     * @request GET:/assets/{asset_id}/sites
     * @secure
     */
    getSitesForAsset: (assetId: string, params: RequestParams = {}) =>
      this.request<CreateSiteInfoResponseApi[], HTTPValidationError>({
        path: `/assets/${assetId}/sites`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/resources
     * @name UploadAssetResource
     * @summary Upload Asset Resource
     * @request POST:/assets/{asset_id}/resources
     * @deprecated
     * @secure
     */
    uploadAssetResource: (
      assetId: string,
      data: BodyUploadAssetResourceAssetsAssetIdResourcesPost,
      query?: {
        /** Description */
        description?: string | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/assets/${assetId}/resources`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/resources
     * @name GetAssetResources
     * @summary Get Asset Resources
     * @request GET:/assets/{asset_id}/resources
     * @deprecated
     * @secure
     */
    getAssetResources: (assetId: string, params: RequestParams = {}) =>
      this.request<ResourceInfo[], HTTPValidationError>({
        path: `/assets/${assetId}/resources`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/resources
     * @name DeleteAssetResources
     * @summary Delete Asset Resources
     * @request DELETE:/assets/{asset_id}/resources
     * @deprecated
     * @secure
     */
    deleteAssetResources: (assetId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/assets/${assetId}/resources`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/resources
     * @name GetAssetResourceById
     * @summary Get Asset Resource By Id
     * @request GET:/assets/{asset_id}/resources/{resource_id}
     * @deprecated
     * @secure
     */
    getAssetResourceById: (
      assetId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/assets/${assetId}/resources/${resourceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/resources
     * @name UpdateAssetResourceDescription
     * @summary Update Asset Resource Description
     * @request PATCH:/assets/{asset_id}/resources/{resource_id}
     * @deprecated
     * @secure
     */
    updateAssetResourceDescription: (
      assetId: string,
      resourceId: string,
      query: {
        /** Description */
        description: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/assets/${assetId}/resources/${resourceId}`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/resources
     * @name DeleteAssetResourceById
     * @summary Delete Asset Resource By Id
     * @request DELETE:/assets/{asset_id}/resources/{resource_id}
     * @deprecated
     * @secure
     */
    deleteAssetResourceById: (
      assetId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/assets/${assetId}/resources/${resourceId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags assets/resources
     * @name DownloadAssetResourceById
     * @summary Download Asset Resource By Id
     * @request GET:/assets/{asset_id}/resources/{resource_id}/download
     * @deprecated
     * @secure
     */
    downloadAssetResourceById: (
      assetId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceDownloadInfo, HTTPValidationError>({
        path: `/assets/${assetId}/resources/${resourceId}/download`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  groups = {
    /**
     * No description
     *
     * @tags groups
     * @name CreateGroup
     * @summary Create Group
     * @request POST:/groups/
     * @secure
     */
    createGroup: (data: CreateGroupRequest, params: RequestParams = {}) =>
      this.request<GroupResponse, HTTPValidationError>({
        path: `/groups/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups
     * @name GetGroups
     * @summary Get Groups
     * @request GET:/groups/
     * @secure
     */
    getGroups: (
      query?: {
        /**
         * Metadata
         * @default false
         */
        metadata?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<GroupResponse[], HTTPValidationError>({
        path: `/groups/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the list of group types that are supported in IRIS.
     *
     * @tags groups
     * @name GetGroupTypes
     * @summary Get Group Types
     * @request GET:/groups/type
     * @secure
     */
    getGroupTypes: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/groups/type`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups
     * @name GetGroup
     * @summary Get Group
     * @request GET:/groups/{group_id}
     * @secure
     */
    getGroup: (
      groupId: string,
      query?: {
        /**
         * Metadata
         * @default false
         */
        metadata?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<GroupResponse, HTTPValidationError>({
        path: `/groups/${groupId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups
     * @name UpdateGroup
     * @summary Update Group
     * @request PATCH:/groups/{group_id}
     * @secure
     */
    updateGroup: (
      groupId: string,
      data: GroupUpdateRequest,
      query?: {
        /**
         * Metadata
         * @default false
         */
        metadata?: boolean;
      },
      params: RequestParams = {}
    ) =>
      this.request<GroupResponse, HTTPValidationError>({
        path: `/groups/${groupId}`,
        method: "PATCH",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups
     * @name DeleteGroup
     * @summary Delete Group
     * @request DELETE:/groups/{group_id}
     * @secure
     */
    deleteGroup: (groupId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/groups/${groupId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups
     * @name GetDetailGroup
     * @summary Get Detail Group
     * @request GET:/groups/{group_id}/details
     * @deprecated
     * @secure
     */
    getDetailGroup: (groupId: string, params: RequestParams = {}) =>
      this.request<AssetsDetail, HTTPValidationError>({
        path: `/groups/${groupId}/details`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups
     * @name AssignAssetToGroup
     * @summary Assign Asset To Group
     * @request POST:/groups/{group_id}/assets/{asset_id}
     * @secure
     */
    assignAssetToGroup: (
      groupId: string,
      assetId: string,
      params: RequestParams = {}
    ) =>
      this.request<GroupAssignmentResponse, HTTPValidationError>({
        path: `/groups/${groupId}/assets/${assetId}`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups
     * @name RemoveAssetFromGroup
     * @summary Remove Asset From Group
     * @request DELETE:/groups/{group_id}/assets/{asset_id}
     * @secure
     */
    removeAssetFromGroup: (
      groupId: string,
      assetId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/groups/${groupId}/assets/${assetId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups
     * @name GetAssetsFromGroup
     * @summary Get Assets From Group
     * @request GET:/groups/{group_id}/assets
     * @secure
     */
    getAssetsFromGroup: (
      groupId: string,
      query?: {
        /** Cursor */
        cursor?: string | null;
        /** Limit */
        limit?: number | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedAssetBasic, HTTPValidationError>({
        path: `/groups/${groupId}/assets`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups/resources
     * @name UploadGroupResource
     * @summary Upload Group Resource
     * @request POST:/groups/{group_id}/resources
     * @deprecated
     * @secure
     */
    uploadGroupResource: (
      groupId: string,
      data: BodyUploadGroupResourceGroupsGroupIdResourcesPost,
      query?: {
        /** Description */
        description?: string | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/groups/${groupId}/resources`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups/resources
     * @name GetGroupResources
     * @summary Get Group Resources
     * @request GET:/groups/{group_id}/resources
     * @deprecated
     * @secure
     */
    getGroupResources: (groupId: string, params: RequestParams = {}) =>
      this.request<ResourceInfo[], HTTPValidationError>({
        path: `/groups/${groupId}/resources`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups/resources
     * @name DeleteGroupResources
     * @summary Delete Group Resources
     * @request DELETE:/groups/{group_id}/resources
     * @deprecated
     * @secure
     */
    deleteGroupResources: (groupId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/groups/${groupId}/resources`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups/resources
     * @name GetGroupResourceById
     * @summary Get Group Resource By Id
     * @request GET:/groups/{group_id}/resources/{resource_id}
     * @deprecated
     * @secure
     */
    getGroupResourceById: (
      groupId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/groups/${groupId}/resources/${resourceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups/resources
     * @name UpdateGroupResourceDescription
     * @summary Update Group Resource Description
     * @request PATCH:/groups/{group_id}/resources/{resource_id}
     * @deprecated
     * @secure
     */
    updateGroupResourceDescription: (
      groupId: string,
      resourceId: string,
      query: {
        /** Description */
        description: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/groups/${groupId}/resources/${resourceId}`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups/resources
     * @name DeleteGroupResourceById
     * @summary Delete Group Resource By Id
     * @request DELETE:/groups/{group_id}/resources/{resource_id}
     * @deprecated
     * @secure
     */
    deleteGroupResourceById: (
      groupId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/groups/${groupId}/resources/${resourceId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags groups/resources
     * @name DownloadGroupResourceById
     * @summary Download Group Resource By Id
     * @request GET:/groups/{group_id}/resources/{resource_id}/download
     * @deprecated
     * @secure
     */
    downloadGroupResourceById: (
      groupId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceDownloadInfo, HTTPValidationError>({
        path: `/groups/${groupId}/resources/${resourceId}/download`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  portfolioViewer = {
    /**
     * @description Get the details of the assets in the portfolio. Assets with all statuses are returned by default.
     *
     * @tags portfolio-viewer
     * @name GetDetailPortfolio
     * @summary Get Detail Portfolio
     * @request GET:/portfolio-viewer/
     * @secure
     */
    getDetailPortfolio: (
      query?: {
        /** Statuses */
        statuses?: AssetStatusEnum[];
        /** Primary Uses */
        primary_uses?: BuildingUseEnum[] | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<AssetsDetail, HTTPValidationError>({
        path: `/portfolio-viewer/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  topUses = {
    /**
     * No description
     *
     * @tags top-uses
     * @name GetTopUses
     * @summary Get Top Uses
     * @request GET:/top-uses/
     * @secure
     */
    getTopUses: (params: RequestParams = {}) =>
      this.request<TopUsesResponse[], any>({
        path: `/top-uses/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  sites = {
    /**
     * No description
     *
     * @tags sites
     * @name CreateSite
     * @summary Create Site
     * @request POST:/sites/
     * @secure
     */
    createSite: (data: SiteInfo, params: RequestParams = {}) =>
      this.request<CreateSiteInfoResponseApi, HTTPValidationError>({
        path: `/sites/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags sites
     * @name GetFootprintByGeolocation
     * @summary Get Footprint By Geolocation
     * @request GET:/sites/footprint
     * @secure
     */
    getFootprintByGeolocation: (
      query: {
        /** Latitude */
        latitude: number;
        /** Longitude */
        longitude: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<SiteFootprintResponse, HTTPValidationError>({
        path: `/sites/footprint`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags sites
     * @name GetSiteById
     * @summary Get Site By Id
     * @request GET:/sites/{site_id}
     * @secure
     */
    getSiteById: (siteId: string, params: RequestParams = {}) =>
      this.request<CreateSiteInfoResponseApi, HTTPValidationError>({
        path: `/sites/${siteId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags sites
     * @name EditSite
     * @summary Edit Site
     * @request PATCH:/sites/{site_id}
     * @secure
     */
    editSite: (
      siteId: string,
      data: UpdateSiteInfoRequestApi,
      params: RequestParams = {}
    ) =>
      this.request<CreateSiteInfoResponseApi, HTTPValidationError>({
        path: `/sites/${siteId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags sites
     * @name DeleteSite
     * @summary Delete Site
     * @request DELETE:/sites/{site_id}
     * @secure
     */
    deleteSite: (siteId: string, params: RequestParams = {}) =>
      this.request<boolean, HTTPValidationError>({
        path: `/sites/${siteId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags sites
     * @name GetGeolocationInfo
     * @summary Get Geolocation Info
     * @request POST:/sites/geolocation
     * @secure
     */
    getGeolocationInfo: (
      data: SiteCentroidRequest,
      params: RequestParams = {}
    ) =>
      this.request<SiteCoordinates, HTTPValidationError>({
        path: `/sites/geolocation`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  jobs = {
    /**
     * No description
     *
     * @tags jobs
     * @name GetJobsForObject
     * @summary Get Jobs For Object
     * @request GET:/jobs/
     * @secure
     */
    getJobsForObject: (
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ServiceReferenceTypeEnum;
        /**
         * Limit
         * @default 10
         */
        limit?: number;
        /** Cursor */
        cursor?: string | null;
        /** Type */
        type?: JobType | null;
        /** Status */
        status?: StatusEnum | null;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedJobInfo, HTTPValidationError>({
        path: `/jobs/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags jobs
     * @name GetJob
     * @summary Get Job
     * @request GET:/jobs/{job_id}
     * @secure
     */
    getJob: (jobId: string, params: RequestParams = {}) =>
      this.request<JobResponse, HTTPValidationError>({
        path: `/jobs/${jobId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags jobs
     * @name UpdateJob
     * @summary Update Job
     * @request PATCH:/jobs/{job_id}
     * @secure
     */
    updateJob: (
      jobId: string,
      data: JobUpdateRequest,
      params: RequestParams = {}
    ) =>
      this.request<JobResponse, HTTPValidationError>({
        path: `/jobs/${jobId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags jobs
     * @name DeleteJob
     * @summary Delete Job
     * @request DELETE:/jobs/{job_id}
     * @secure
     */
    deleteJob: (jobId: string, params: RequestParams = {}) =>
      this.request<void, HTTPValidationError>({
        path: `/jobs/${jobId}`,
        method: "DELETE",
        secure: true,
        ...params,
      }),

    /**
     * @description Attach a resource to a job. This is used for attaching a resource to a job that has already been created. After the resource has been attached, the job status will be set to completed. - **job_id**: The ID of the Job - **file**: The resource to attach to the job
     *
     * @tags jobs
     * @name AddResourceToJob
     * @summary Add Resource To Job
     * @request POST:/jobs/{job_id}/resources
     * @secure
     */
    addResourceToJob: (
      jobId: string,
      data: BodyAddResourceToJobJobsJobIdResourcesPost,
      params: RequestParams = {}
    ) =>
      this.request<JobResponse, HTTPValidationError>({
        path: `/jobs/${jobId}/resources`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * @description Batch run a job for a list of objects (ex: Assets, Groups, etc.). This job type exports a resource (ex: PDF, PNG, etc.) for a given object based on a particular URL - **ref_type**: The type of object to run the job for - **ref_ids**: The list of object ids to run the job for - **parameters**: Additional input parameters required for the job. 💡 **NOTE** > The **parameters** attribute for the export job requires two parameters: > - **content_type**: The MIME type of the resource to export > - **path** (Optional): Optional path to append to the default url (https://HOST/REF_TYPE/REF_ID/) to the resource. > - **url** (Optional): Optional URL to use instead of the default url (https://HOST/REF_TYPE/REF_ID/)
     *
     * @tags jobs
     * @name BatchRunJobs
     * @summary Batch Run Jobs
     * @request POST:/jobs/export/batch
     * @secure
     */
    batchRunJobs: (
      query: {
        ref_type: ServiceReferenceTypeEnum;
      },
      data: BodyBatchRunJobsJobsExportBatchPost,
      params: RequestParams = {}
    ) =>
      this.request<SimpleJobResponse[], HTTPValidationError>({
        path: `/jobs/export/batch`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Batch upload assets from a provided CSV file. The CSV file has to conform to the specified format. To download the template file, please use the following links for the different environments: - [Template (localhost)](http://localhost:8080/static/batch_upload_assets_template.csv) - [Template (dev)](https://asset.dev.iris.arup.com/static/batch_upload_assets_template.csv) - [Template (test)](https://asset.test.iris.arup.com/static/batch_upload_assets_template.csv) - [Template (prod)](https://asset.iris.arup.com/static/batch_upload_assets_template.csv) **Parameters**: - **file**: The CSV file with all the assets to create.
     *
     * @tags jobs
     * @name BatchCreateAssets
     * @summary Batch Create Assets
     * @request POST:/jobs/asset/batch
     * @secure
     */
    batchCreateAssets: (
      data: BodyBatchCreateAssetsJobsAssetBatchPost,
      params: RequestParams = {}
    ) =>
      this.request<SimpleJobResponse, HTTPValidationError>({
        path: `/jobs/asset/batch`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),
  };
  resources = {
    /**
     * No description
     *
     * @tags resources
     * @name UploadResource
     * @summary Upload Resource
     * @request POST:/resources/
     * @secure
     */
    uploadResource: (
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ServiceReferenceTypeEnum;
        /** Description */
        description?: string | null;
      },
      data: BodyUploadResourceResourcesPost,
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/resources/`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags resources
     * @name GetResources
     * @summary Get Resources
     * @request GET:/resources/
     * @secure
     */
    getResources: (
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ServiceReferenceTypeEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo[], HTTPValidationError>({
        path: `/resources/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags resources
     * @name DeleteResources
     * @summary Delete Resources
     * @request DELETE:/resources/
     * @secure
     */
    deleteResources: (
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ServiceReferenceTypeEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/resources/`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags resources
     * @name GetResourceById
     * @summary Get Resource By Id
     * @request GET:/resources/{resource_id}
     * @secure
     */
    getResourceById: (
      resourceId: string,
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ServiceReferenceTypeEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/resources/${resourceId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags resources
     * @name UpdateResourceDescription
     * @summary Update Resource Description
     * @request PATCH:/resources/{resource_id}
     * @secure
     */
    updateResourceDescription: (
      resourceId: string,
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ServiceReferenceTypeEnum;
        /** Description */
        description: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/resources/${resourceId}`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags resources
     * @name DeleteResourceById
     * @summary Delete Resource By Id
     * @request DELETE:/resources/{resource_id}
     * @secure
     */
    deleteResourceById: (
      resourceId: string,
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ServiceReferenceTypeEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/resources/${resourceId}`,
        method: "DELETE",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags resources
     * @name DownloadResource
     * @summary Download Resource
     * @request GET:/resources/{resource_id}/download
     * @secure
     */
    downloadResource: (
      resourceId: string,
      query: {
        /** Ref Id */
        ref_id: string;
        ref_type: ServiceReferenceTypeEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceDownloadInfo, HTTPValidationError>({
        path: `/resources/${resourceId}/download`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
}

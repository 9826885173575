/** Body_upload_comment_resource_recommendations__recommendation_id__comments__comment_id__resources_post */
export interface BodyUploadCommentResourceRecommendationsRecommendationIdCommentsCommentIdResourcesPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_comment_resource_strategies__strategy_id__comments__comment_id__resources_post */
export interface BodyUploadCommentResourceStrategiesStrategyIdCommentsCommentIdResourcesPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_resource_recommendations__recommendation_id__resources_post */
export interface BodyUploadResourceRecommendationsRecommendationIdResourcesPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** Body_upload_resource_strategies__strategy_id__resources_post */
export interface BodyUploadResourceStrategiesStrategyIdResourcesPost {
  /**
   * File
   * @format binary
   */
  file: File;
}

/** CommentInfoRequest */
export interface CommentInfoRequest {
  /**
   * Comment
   * Comment.
   * @example "This is a comment"
   */
  comment: string;
}

/** CommentInfoResponse */
export interface CommentInfoResponse {
  /**
   * Comment
   * Comment.
   * @example "This is a comment"
   */
  comment: string;
  /**
   * Id
   * Main key to identify each comment. Id cannot be repeated and should not be modified
   * @example "uuid"
   */
  id: string;
  /**
   * Object Id
   * Id (uuid) of the object (strategy/ recommendation) to which the comment is assigned to.
   * @example "user@arup.com"
   */
  object_id: string;
  /**
   * Type of the object to which the comment is assigned to. Possible values: ASSET, GROUP, RECOMMENDATION, STRATEGY, COMMENT
   * @example "user@arup.com"
   */
  object_type: ObjectTypeEnum;
  /**
   * Created At
   * When the comment was created [UTC].
   * @format date-time
   * @example "2022-06-01T10:54:30.772041+00:00"
   */
  created_at: string;
  /**
   * Created By
   * User who created the comment.
   * @example "user@arup.com"
   */
  created_by: string;
  /**
   * Updated At
   * When the comment was updated [UTC].
   * @format date-time
   * @example "2022-07-01T10:54:30.772041+00:00"
   */
  updated_at?: string;
  /**
   * Updated By
   * User who updated the comment.
   * @example "user@arup.com"
   */
  updated_by?: string;
}

/** CreateRecommendationRequestApi */
export interface CreateRecommendationRequestApi {
  /**
   * Title
   * Recommendation title
   * @example "Recommendation 01"
   */
  title: string;
  /**
   * Description
   * My Recommendation description
   * @example "This is a Recommendation record"
   */
  description?: string;
  /**
   * Assessment Id
   * Id of the assessment (uuid).
   * @example "5514bf3b-415a-4df7-bede-3629dfcdb87e"
   */
  assessment_id?: string;
  /**
   * Cost
   * Cost to implement recommendation [USD].
   * @example 23.31
   */
  cost?: number;
  /**
   * Recommendation type. Possible values: Physical Intervention, Operation Measures, Further Assessment, Divestment.
   * @example "Physical Intervention"
   */
  recommendation_type: RecommendationTypesEnum;
  /**
   * Recommendation Subtype
   * Recommendation Subtype.             -When recommendation_type: Physical Intervention, Possible values: Structural retrofit, Non-structural retrofit, Elevate, move, or armor equipment, Elevate, move, or armor inventory, Provide or increase capacity of heating/cooling systems, Provide or increase back-up systems/capacity, Strengthen/protect infrastructure, Enhanced storm-water detention/retention, External/internal flood protection measures, External wind protection measures, Defensible space, Other;             -When recommendation_type: Operation Measures, Possible values: Move populations, Reduce impeding factors, Improve maintenance, Preparedness/scenario planning, Business continuity planning, Training and drills, Communications/awareness campaign, Adaptation planning over useful life, Other;            -When recommendation_type: Further Assessment, Possible values: Detailed risk analysis (increase Risk Assessment Class), Cost-benefit analysis and investment prioritization, Retrofit design, Resilience-based design, Detailed utility risk analysis, Detailed site access risk analysis, Workforce impact assessment, Population impact assessment, Secondary/cascading risk assessment, Include in shared fate risk analysis, Codes and regulations gap analysis, Other;            -When recommendation_type: Operation Measures, Possible values: Break lease or allow to expire, Vacate property, Sell property, Demolish building, Other.
   * @example "Defensible space"
   */
  recommendation_subtype:
    | PhysicalInterventionsEnum
    | OperationalMeasuresEnum
    | FurtherAssessmentEnum
    | DivestmentEnum;
  /**
   * Recommendation Status. Possible values: TO DO, PLANNING, IN PROGRESS, DONE.
   * @default "TO DO"
   * @example "TO DO"
   */
  status?: RecommendationStatusEnum;
  /**
   * Due Date
   * Due date of the recommendation [YYYY-MM-DD].
   * @example "2022-08-01"
   */
  due_date?: string;
  /**
   * Sort Order
   * Integer value used in the Frond End to sort the recommendations view when multiple recommendations are shown.
   * @min 1
   * @default 1
   * @example 1
   */
  sort_order?: number;
}

/** CreateStrategyRequestApi */
export interface CreateStrategyRequestApi {
  /**
   * Name
   * Name of the strategy.
   * @example "Strategy 1"
   */
  name: string;
  /**
   * Description
   * Description of the strategy.
   * @example "Description of the strategy."
   */
  description?: string;
  /**
   * Priority of the strategy. Possible values: HIGHEST, HIGH, MEDIUM, LOW, NOT PRIORITIZED YET.
   * @example "HIGH"
   */
  priority: PriorityEnum;
  /**
   * Status of the strategy. Possible values: PROPOSED, ACCEPTED, REJECTED, IN PROGRESS, DONE.
   * @default "PROPOSED"
   * @example "PROPOSED"
   */
  status?: StrategyStatusEnum;
  /**
   * Assessment Id
   * Assessment id to which the strategy is related to.
   * @example "assessment_id"
   */
  assessment_id?: string;
  /**
   * Cost Benefit Ratio
   * Cost-benefit ratio of the strategy.
   * @example 2.15
   */
  cost_benefit_ratio?: number;
  /**
   * Cost Of Implementation
   * Cost of the strategy. Unit: $
   * @example 12000000
   */
  cost_of_implementation?: number;
  /**
   * Cost Per Unit Of Area
   * Cost of the strategy per unit of area. Unit: $/unit of area.
   * @example 750
   */
  cost_per_unit_of_area?: number;
  /**
   * Annualized Losses
   * Annualized losses ... Unit: $.
   * @example 1500000
   */
  annualized_losses?: number;
  /**
   * Annualized Avoided Losses
   * Annualized avoided losses ... Unit: $.
   * @example 500000
   */
  annualized_avoided_losses?: number;
  /**
   * Time Of Disruption
   * Time of disruption due to the implementation of the strategy. Unit: weeks.
   * @example 20
   */
  time_of_disruption?: number;
  /**
   * Life Extension
   * Life extension of the asset/group of assets as consequence of the implementation of the strategy. Unit: years.
   * @example 12.5
   */
  life_extension?: number;
  /**
   * Payback Ratio
   * Payback ratio of the strategy. Unit: years.
   * @example 2.3
   */
  payback_ratio?: number;
  /**
   * Sort Order
   * Integer value used in the Frond End to sort the strategies view when multiple strategies are shown.
   * @min 1
   * @default 1
   * @example 1
   */
  sort_order?: number;
}

/**
 * DivestmentEnum
 * An enumeration.
 */
export enum DivestmentEnum {
  BreakLeaseOrAllowToExpire = "Break lease or allow to expire",
  VacateProperty = "Vacate property",
  SellProperty = "Sell property",
  DemolishBuilding = "Demolish building",
  Other = "Other",
}

/**
 * FurtherAssessmentEnum
 * An enumeration.
 */
export enum FurtherAssessmentEnum {
  DetailedRiskAnalysisIncreaseRiskAssessmentClass = "Detailed risk analysis (increase Risk Assessment Class)",
  CostBenefitAnalysisAndInvestmentPrioritization = "Cost-benefit analysis and investment prioritization",
  RetrofitDesign = "Retrofit design",
  ResilienceBasedDesign = "Resilience-based design",
  DetailedUtilityRiskAnalysis = "Detailed utility risk analysis",
  DetailedSiteAccessRiskAnalysis = "Detailed site access risk analysis",
  WorkforceImpactAssessment = "Workforce impact assessment",
  PopulationImpactAssessment = "Population impact assessment",
  SecondaryCascadingRiskAssessment = "Secondary/cascading risk assessment",
  IncludeInSharedFateRiskAnalysis = "Include in shared fate risk analysis",
  CodesAndRegulationsGapAnalysis = "Codes and regulations gap analysis",
  Other = "Other",
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/**
 * ObjectTypeEnum
 * An enumeration.
 */
export enum ObjectTypeEnum {
  ASSET = "ASSET",
  GROUP = "GROUP",
  RECOMMENDATION = "RECOMMENDATION",
  STRATEGY = "STRATEGY",
  COMMENT = "COMMENT",
}

/**
 * OperationalMeasuresEnum
 * An enumeration.
 */
export enum OperationalMeasuresEnum {
  MovePopulations = "Move populations",
  ReduceImpedingFactors = "Reduce impeding factors",
  ImproveMaintenance = "Improve maintenance",
  PreparednessScenarioPlanning = "Preparedness/scenario planning",
  BusinessContinuityPlanning = "Business continuity planning",
  TrainingAndDrills = "Training and drills",
  CommunicationsAwarenessCampaign = "Communications/awareness campaign",
  AdaptationPlanningOverUsefulLife = "Adaptation planning over useful life",
  Other = "Other",
}

/**
 * PaginatedRecommendationInfo
 * This model should be extended to create a standard paginated response for our apis.
 */
export interface PaginatedRecommendationInfo {
  /**
   * Results
   * Page of results requested
   */
  results: RecommendationResponseApi[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   * @example "a12vsf"
   */
  cursor?: string;
}

/**
 * PaginatedStrategyInfo
 * This model should be extended to create a standard paginated response for our apis.
 */
export interface PaginatedStrategyInfo {
  /**
   * Results
   * Page of results requested
   */
  results: StrategyResponseDetailedApi[];
  /**
   * Cursor
   * Cursor to query for the next page. None when results are the last page.
   * @example "a12vsf"
   */
  cursor?: string;
}

/**
 * PhysicalInterventionsEnum
 * An enumeration.
 */
export enum PhysicalInterventionsEnum {
  StructuralRetrofit = "Structural retrofit",
  NonStructuralRetrofit = "Non-structural retrofit",
  ElevateMoveOrArmorEquipment = "Elevate, move, or armor equipment",
  ElevateMoveOrArmorInventory = "Elevate, move, or armor inventory",
  ProvideOrIncreaseCapacityOfHeatingCoolingSystems = "Provide or increase capacity of heating/cooling systems",
  ProvideOrIncreaseBackUpSystemsCapacity = "Provide or increase back-up systems/capacity",
  StrengthenProtectInfrastructure = "Strengthen/protect infrastructure",
  EnhancedStormWaterDetentionRetention = "Enhanced storm-water detention/retention",
  ExternalInternalFloodProtectionMeasures = "External/internal flood protection measures",
  ExternalWindProtectionMeasures = "External wind protection measures",
  DefensibleSpace = "Defensible space",
  Other = "Other",
}

/**
 * PriorityEnum
 * An enumeration.
 */
export enum PriorityEnum {
  HIGHEST = "HIGHEST",
  HIGH = "HIGH",
  MEDIUM = "MEDIUM",
  LOW = "LOW",
  NOTPRIORITIZEDYET = "NOT PRIORITIZED YET",
}

/** RecommendationResponseApi */
export interface RecommendationResponseApi {
  /**
   * Title
   * Recommendation title
   * @example "Recommendation 01"
   */
  title: string;
  /**
   * Description
   * My Recommendation description
   * @example "This is a Recommendation record"
   */
  description?: string;
  /**
   * Assessment Id
   * Id of the assessment (uuid).
   * @example "5514bf3b-415a-4df7-bede-3629dfcdb87e"
   */
  assessment_id?: string;
  /**
   * Cost
   * Cost to implement recommendation [USD].
   * @example 23.31
   */
  cost?: number;
  /**
   * Recommendation type. Possible values: Physical Intervention, Operation Measures, Further Assessment, Divestment.
   * @example "Physical Intervention"
   */
  recommendation_type: RecommendationTypesEnum;
  /**
   * Recommendation Subtype
   * Recommendation Subtype.             -When recommendation_type: Physical Intervention, Possible values: Structural retrofit, Non-structural retrofit, Elevate, move, or armor equipment, Elevate, move, or armor inventory, Provide or increase capacity of heating/cooling systems, Provide or increase back-up systems/capacity, Strengthen/protect infrastructure, Enhanced storm-water detention/retention, External/internal flood protection measures, External wind protection measures, Defensible space, Other;             -When recommendation_type: Operation Measures, Possible values: Move populations, Reduce impeding factors, Improve maintenance, Preparedness/scenario planning, Business continuity planning, Training and drills, Communications/awareness campaign, Adaptation planning over useful life, Other;            -When recommendation_type: Further Assessment, Possible values: Detailed risk analysis (increase Risk Assessment Class), Cost-benefit analysis and investment prioritization, Retrofit design, Resilience-based design, Detailed utility risk analysis, Detailed site access risk analysis, Workforce impact assessment, Population impact assessment, Secondary/cascading risk assessment, Include in shared fate risk analysis, Codes and regulations gap analysis, Other;            -When recommendation_type: Operation Measures, Possible values: Break lease or allow to expire, Vacate property, Sell property, Demolish building, Other.
   * @example "Defensible space"
   */
  recommendation_subtype:
    | PhysicalInterventionsEnum
    | OperationalMeasuresEnum
    | FurtherAssessmentEnum
    | DivestmentEnum;
  /**
   * Recommendation Status. Possible values: TO DO, PLANNING, IN PROGRESS, DONE.
   * @default "TO DO"
   * @example "TO DO"
   */
  status?: RecommendationStatusEnum;
  /**
   * Due Date
   * Due date of the recommendation [YYYY-MM-DD].
   * @example "2022-08-01"
   */
  due_date?: string;
  /**
   * Sort Order
   * Integer value used in the Frond End to sort the recommendations view when multiple recommendations are shown.
   * @min 1
   * @default 1
   * @example 1
   */
  sort_order?: number;
  /**
   * Id
   * Unique identifier (uuid) for the Recommendation
   * @example "Recommendation 01"
   */
  id: string;
  /**
   * Created At
   * When the recommendation was created [UTC].
   * @format date-time
   * @example "2022-06-01T10:54:30.772041+00:00"
   */
  created_at: string;
  /**
   * Created By
   * User who created the recommendation.
   * @example "user@arup.com"
   */
  created_by: string;
  /**
   * Updated At
   * When the recommendation was updated [UTC].
   * @format date-time
   * @example "2022-07-01T10:54:30.772041+00:00"
   */
  updated_at?: string;
  /**
   * Updated By
   * User who updated the recommendation.
   * @example "user@arup.com"
   */
  updated_by?: string;
}

/**
 * RecommendationStatusEnum
 * An enumeration.
 */
export enum RecommendationStatusEnum {
  TODO = "TO DO",
  PLANNING = "PLANNING",
  INPROGRESS = "IN PROGRESS",
  DONE = "DONE",
}

/**
 * RecommendationTypesEnum
 * An enumeration.
 */
export enum RecommendationTypesEnum {
  PhysicalIntervention = "Physical Intervention",
  OperationMeasures = "Operation Measures",
  FurtherAssessment = "Further Assessment",
  Divestment = "Divestment",
}

/** ResourceDownloadInfo */
export interface ResourceDownloadInfo {
  /**
   * Id
   * Unique identifier (uuid) for the resource.
   * @example "b9cf0be6-4057-45b8-8e38-a23b5fee1941"
   */
  id: string;
  /**
   * File Name
   * Name of the file.
   * @example "report"
   */
  file_name: string;
  /**
   * Content Type
   * MIME type of the file.
   * @example "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
   */
  content_type: string;
  /**
   * File Extension
   * Extension of the file.
   * @example "docx"
   */
  file_extension: string;
  /**
   * Created By
   * User who uploaded the file.
   * @example "user@arup.com"
   */
  created_by: string;
  /**
   * Presigned Url
   * S3 presigned url for downloading the resource.
   * @example "..."
   */
  presigned_url: string;
}

/** ResourceInfo */
export interface ResourceInfo {
  /**
   * Id
   * Unique identifier (uuid) for the resource.
   * @example "b9cf0be6-4057-45b8-8e38-a23b5fee1941"
   */
  id: string;
  /**
   * File Name
   * Name of the file.
   * @example "report"
   */
  file_name: string;
  /**
   * Content Type
   * MIME type of the file.
   * @example "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
   */
  content_type: string;
  /**
   * File Extension
   * Extension of the file.
   * @example "docx"
   */
  file_extension: string;
  /**
   * Created By
   * User who uploaded the file.
   * @example "user@arup.com"
   */
  created_by: string;
  /**
   * Object Id
   * Id (uuid) of the object (asset/ group of assets) to which the resource is assigned to.
   * @example "user@arup.com"
   */
  object_id: string;
  /**
   * Type of the object to which the resource is assigned to. Possible values: STRATEGY,RECOMMENDATION,COMMENT
   * @example "user@arup.com"
   */
  object_type: ObjectTypeEnum;
  /**
   * Description
   * Brief description of the file
   * @example "Inspection report ..."
   */
  description?: string;
  /**
   * Created At
   * When the file was uploaded.
   * @format date-time
   * @example "2022-06-01T06:41:07.689488+00:00"
   */
  created_at: string;
  /**
   * Updated At
   * When the new version of the file was uploaded.
   * @format date-time
   * @example "2022-06-01T06:41:07.689488+00:00"
   */
  updated_at?: string;
  /**
   * Updated By
   * User who uploaded the new version of file.
   * @example "user@arup.com"
   */
  updated_by?: string;
}

/** StrategyAssignmentResponseApi */
export interface StrategyAssignmentResponseApi {
  /**
   * Type of object to assign to the strategy. Possible values: ASSET, GROUP, RECOMMENDATION, STRATEGY, COMMENT.
   * @example "ASSET"
   */
  object_type: ObjectTypeEnum;
  /**
   * Strategy Id
   * ID (uuid) of the strategy
   * @example "4b4300d7-98ad-4872-a033-1b63b0eaa346"
   */
  strategy_id: string;
  /**
   * Object Id
   * Id of the object (recommendation, asset or group) assigned to the strategy.
   * @example "Site 01"
   */
  object_id: string;
  /**
   * Id
   * Unique identifier (uuid) for the strategy assignment
   * @example "f61de9ce-f3da-4ae7-887c-f1389134d49a"
   */
  id: string;
}

/** StrategyObjectsIdResponseApi */
export interface StrategyObjectsIdResponseApi {
  /**
   * Ids
   * List of objects of type "object_type" belonging to a strategy.
   * @example ["object_1_id","object_2_id","object_3_id"]
   */
  ids: string[];
  /**
   * Type of the object to be assigned to a strategy. Possible values: ASSET, GROUP, RECOMMENDATION, STRATEGY, COMMENT.
   * @example "ASSET"
   */
  object_type: ObjectTypeEnum;
}

/** StrategyResponseApi */
export interface StrategyResponseApi {
  /**
   * Name
   * Name of the strategy.
   * @example "Strategy 1"
   */
  name: string;
  /**
   * Description
   * Description of the strategy.
   * @example "Description of the strategy."
   */
  description?: string;
  /**
   * Priority of the strategy. Possible values: HIGHEST, HIGH, MEDIUM, LOW, NOT PRIORITIZED YET.
   * @example "HIGH"
   */
  priority: PriorityEnum;
  /**
   * Status of the strategy. Possible values: PROPOSED, ACCEPTED, REJECTED, IN PROGRESS, DONE.
   * @default "PROPOSED"
   * @example "PROPOSED"
   */
  status?: StrategyStatusEnum;
  /**
   * Assessment Id
   * Assessment id to which the strategy is related to.
   * @example "assessment_id"
   */
  assessment_id?: string;
  /**
   * Cost Benefit Ratio
   * Cost-benefit ratio of the strategy.
   * @example 2.15
   */
  cost_benefit_ratio?: number;
  /**
   * Cost Of Implementation
   * Cost of the strategy. Unit: $
   * @example 12000000
   */
  cost_of_implementation?: number;
  /**
   * Cost Per Unit Of Area
   * Cost of the strategy per unit of area. Unit: $/unit of area.
   * @example 750
   */
  cost_per_unit_of_area?: number;
  /**
   * Annualized Losses
   * Annualized losses ... Unit: $.
   * @example 1500000
   */
  annualized_losses?: number;
  /**
   * Annualized Avoided Losses
   * Annualized avoided losses ... Unit: $.
   * @example 500000
   */
  annualized_avoided_losses?: number;
  /**
   * Time Of Disruption
   * Time of disruption due to the implementation of the strategy. Unit: weeks.
   * @example 20
   */
  time_of_disruption?: number;
  /**
   * Life Extension
   * Life extension of the asset/group of assets as consequence of the implementation of the strategy. Unit: years.
   * @example 12.5
   */
  life_extension?: number;
  /**
   * Payback Ratio
   * Payback ratio of the strategy. Unit: years.
   * @example 2.3
   */
  payback_ratio?: number;
  /**
   * Sort Order
   * Integer value used in the Frond End to sort the strategies view when multiple strategies are shown.
   * @min 1
   * @default 1
   * @example 1
   */
  sort_order?: number;
  /**
   * Id
   * Main key to identify each strategy. Id cannot be repeated and should not be modified
   * @example "uuid"
   */
  id: string;
  /**
   * Created At
   * When the strategy was created [UTC].
   * @format date-time
   * @example "2022-06-01T10:54:30.772041+00:00"
   */
  created_at: string;
  /**
   * Created By
   * User who created the strategy.
   * @example "user@arup.com"
   */
  created_by: string;
  /**
   * Updated At
   * When the strategy was updated [UTC].
   * @format date-time
   * @example "2022-07-01T10:54:30.772041+00:00"
   */
  updated_at?: string;
  /**
   * Updated By
   * User who updated the strategy.
   * @example "user@arup.com"
   */
  updated_by?: string;
}

/** StrategyResponseDetailedApi */
export interface StrategyResponseDetailedApi {
  /**
   * Name
   * Name of the strategy.
   * @example "Strategy 1"
   */
  name: string;
  /**
   * Description
   * Description of the strategy.
   * @example "Description of the strategy."
   */
  description?: string;
  /**
   * Priority of the strategy. Possible values: HIGHEST, HIGH, MEDIUM, LOW, NOT PRIORITIZED YET.
   * @example "HIGH"
   */
  priority: PriorityEnum;
  /**
   * Status of the strategy. Possible values: PROPOSED, ACCEPTED, REJECTED, IN PROGRESS, DONE.
   * @default "PROPOSED"
   * @example "PROPOSED"
   */
  status?: StrategyStatusEnum;
  /**
   * Assessment Id
   * Assessment id to which the strategy is related to.
   * @example "assessment_id"
   */
  assessment_id?: string;
  /**
   * Cost Benefit Ratio
   * Cost-benefit ratio of the strategy.
   * @example 2.15
   */
  cost_benefit_ratio?: number;
  /**
   * Cost Of Implementation
   * Cost of the strategy. Unit: $
   * @example 12000000
   */
  cost_of_implementation?: number;
  /**
   * Cost Per Unit Of Area
   * Cost of the strategy per unit of area. Unit: $/unit of area.
   * @example 750
   */
  cost_per_unit_of_area?: number;
  /**
   * Annualized Losses
   * Annualized losses ... Unit: $.
   * @example 1500000
   */
  annualized_losses?: number;
  /**
   * Annualized Avoided Losses
   * Annualized avoided losses ... Unit: $.
   * @example 500000
   */
  annualized_avoided_losses?: number;
  /**
   * Time Of Disruption
   * Time of disruption due to the implementation of the strategy. Unit: weeks.
   * @example 20
   */
  time_of_disruption?: number;
  /**
   * Life Extension
   * Life extension of the asset/group of assets as consequence of the implementation of the strategy. Unit: years.
   * @example 12.5
   */
  life_extension?: number;
  /**
   * Payback Ratio
   * Payback ratio of the strategy. Unit: years.
   * @example 2.3
   */
  payback_ratio?: number;
  /**
   * Sort Order
   * Integer value used in the Frond End to sort the strategies view when multiple strategies are shown.
   * @min 1
   * @default 1
   * @example 1
   */
  sort_order?: number;
  /**
   * Id
   * Main key to identify each strategy. Id cannot be repeated and should not be modified
   * @example "uuid"
   */
  id: string;
  /**
   * Created At
   * When the strategy was created [UTC].
   * @format date-time
   * @example "2022-06-01T10:54:30.772041+00:00"
   */
  created_at: string;
  /**
   * Created By
   * User who created the strategy.
   * @example "user@arup.com"
   */
  created_by: string;
  /**
   * Updated At
   * When the strategy was updated [UTC].
   * @format date-time
   * @example "2022-07-01T10:54:30.772041+00:00"
   */
  updated_at?: string;
  /**
   * Updated By
   * User who updated the strategy.
   * @example "user@arup.com"
   */
  updated_by?: string;
  /**
   * Asset Ids
   * List of asset ids (uuid) to which the strategy is assigned to.
   * @example ["009548f6-aec4-42cf-acc0-cd792003ca06","08e2aac3-4606-47d0-8099-f9232a14c781"]
   */
  asset_ids?: string[];
  /**
   * Group Ids
   * List of group asset ids (uuid) to which the strategy is assigned to.
   * @example ["4b8a3822-4c7f-4adf-a76f-df42a3da1b23","2c94308f-3ba1-47a7-ba3b-521d8bf6a30c"]
   */
  group_ids?: string[];
}

/**
 * StrategyStatusEnum
 * An enumeration.
 */
export enum StrategyStatusEnum {
  PROPOSED = "PROPOSED",
  ACCEPTED = "ACCEPTED",
  REJECTED = "REJECTED",
  INPROGRESS = "IN PROGRESS",
  DONE = "DONE",
}

/** UpdateRecommendationRequestApi */
export interface UpdateRecommendationRequestApi {
  /**
   * Title
   * Recommendation title
   * @example "Recommendation 01"
   */
  title?: string;
  /**
   * Description
   * My Recommendation description
   * @example "This is a Recommendation record"
   */
  description?: string;
  /**
   * Assessment Id
   * Id of the assessment (uuid).
   * @example "5514bf3b-415a-4df7-bede-3629dfcdb87e"
   */
  assessment_id?: string;
  /**
   * Cost
   * Cost to implement recommendation [USD].
   * @example 23.31
   */
  cost?: number;
  /**
   * Recommendation type. Possible values: Physical Intervention, Operation Measures, Further Assessment, Divestment.
   * @example "Physical Intervention"
   */
  recommendation_type?: RecommendationTypesEnum;
  /**
   * Recommendation Subtype
   * Recommendation Subtype.             -When recommendation_type: Physical Intervention, Possible values: Structural retrofit, Non-structural retrofit, Elevate, move, or armor equipment, Elevate, move, or armor inventory, Provide or increase capacity of heating/cooling systems, Provide or increase back-up systems/capacity, Strengthen/protect infrastructure, Enhanced storm-water detention/retention, External/internal flood protection measures, External wind protection measures, Defensible space, Other;             -When recommendation_type: Operation Measures, Possible values: Move populations, Reduce impeding factors, Improve maintenance, Preparedness/scenario planning, Business continuity planning, Training and drills, Communications/awareness campaign, Adaptation planning over useful life, Other;            -When recommendation_type: Further Assessment, Possible values: Detailed risk analysis (increase Risk Assessment Class), Cost-benefit analysis and investment prioritization, Retrofit design, Resilience-based design, Detailed utility risk analysis, Detailed site access risk analysis, Workforce impact assessment, Population impact assessment, Secondary/cascading risk assessment, Include in shared fate risk analysis, Codes and regulations gap analysis, Other;            -When recommendation_type: Operation Measures, Possible values: Break lease or allow to expire, Vacate property, Sell property, Demolish building, Other.
   * @example "Defensible space"
   */
  recommendation_subtype?:
    | PhysicalInterventionsEnum
    | OperationalMeasuresEnum
    | FurtherAssessmentEnum
    | DivestmentEnum;
  /**
   * Recommendation Status. Possible values: TO DO, PLANNING, IN PROGRESS, DONE.
   * @default "TO DO"
   * @example "TO DO"
   */
  status?: RecommendationStatusEnum;
  /**
   * Due Date
   * Due date of the recommendation [YYYY-MM-DD].
   * @example "2022-08-01"
   */
  due_date?: string;
  /**
   * Sort Order
   * Integer value used in the Frond End to sort the recommendations view when multiple recommendations are shown.
   * @min 1
   * @default 1
   * @example 1
   */
  sort_order?: number;
}

/** UpdateStrategyRequestApi */
export interface UpdateStrategyRequestApi {
  /**
   * Name
   * Name of the strategy.
   * @example "Strategy 1"
   */
  name?: string;
  /**
   * Description
   * Description of the strategy.
   * @example "Description of the strategy."
   */
  description?: string;
  /**
   * Priority of the strategy. Possible values: HIGHEST, HIGH, MEDIUM, LOW, NOT PRIORITIZED YET.
   * @example "HIGH"
   */
  priority?: PriorityEnum;
  /**
   * Status of the strategy. Possible values: PROPOSED, ACCEPTED, REJECTED, IN PROGRESS, DONE.
   * @default "PROPOSED"
   * @example "PROPOSED"
   */
  status?: StrategyStatusEnum;
  /**
   * Assessment Id
   * Assessment id to which the strategy is related to.
   * @example "assessment_id"
   */
  assessment_id?: string;
  /**
   * Cost Benefit Ratio
   * Cost-benefit ratio of the strategy.
   * @example 2.15
   */
  cost_benefit_ratio?: number;
  /**
   * Cost Of Implementation
   * Cost of the strategy. Unit: $
   * @example 12000000
   */
  cost_of_implementation?: number;
  /**
   * Cost Per Unit Of Area
   * Cost of the strategy per unit of area. Unit: $/unit of area.
   * @example 750
   */
  cost_per_unit_of_area?: number;
  /**
   * Annualized Losses
   * Annualized losses ... Unit: $.
   * @example 1500000
   */
  annualized_losses?: number;
  /**
   * Annualized Avoided Losses
   * Annualized avoided losses ... Unit: $.
   * @example 500000
   */
  annualized_avoided_losses?: number;
  /**
   * Time Of Disruption
   * Time of disruption due to the implementation of the strategy. Unit: weeks.
   * @example 20
   */
  time_of_disruption?: number;
  /**
   * Life Extension
   * Life extension of the asset/group of assets as consequence of the implementation of the strategy. Unit: years.
   * @example 12.5
   */
  life_extension?: number;
  /**
   * Payback Ratio
   * Payback ratio of the strategy. Unit: years.
   * @example 2.3
   */
  payback_ratio?: number;
  /**
   * Sort Order
   * Integer value used in the Frond End to sort the strategies view when multiple strategies are shown.
   * @min 1
   * @default 1
   * @example 1
   */
  sort_order?: number;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<
  FullRequestParams,
  "body" | "method" | "query" | "path"
>;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (
    securityData: SecurityDataType | null
  ) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown>
  extends Response {
  data: D;
  error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
  Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) =>
    fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  protected encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(
      typeof value === "number" ? value : `${value}`
    )}`;
  }

  protected addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  protected addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter(
      (key) => "undefined" !== typeof query[key]
    );
    return keys
      .map((key) =>
        Array.isArray(query[key])
          ? this.addArrayQueryParam(query, key)
          : this.addQueryParam(query, key)
      )
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string")
        ? JSON.stringify(input)
        : input,
    [ContentType.Text]: (input: any) =>
      input !== null && typeof input !== "string"
        ? JSON.stringify(input)
        : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  protected mergeRequestParams(
    params1: RequestParams,
    params2?: RequestParams
  ): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  protected createAbortSignal = (
    cancelToken: CancelToken
  ): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    return this.customFetch(
      `${baseUrl || this.baseUrl || ""}${path}${
        queryString ? `?${queryString}` : ""
      }`,
      {
        ...requestParams,
        headers: {
          ...(requestParams.headers || {}),
          ...(type && type !== ContentType.FormData
            ? { "Content-Type": type }
            : {}),
        },
        signal:
          (cancelToken
            ? this.createAbortSignal(cancelToken)
            : requestParams.signal) || null,
        body:
          typeof body === "undefined" || body === null
            ? null
            : payloadFormatter(body),
      }
    ).then(async (response) => {
      const r = response.clone() as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data;
      return data;
    });
  };
}

/**
 * @title FastAPI
 * @version 0.1.0
 */
export class Api<
  SecurityDataType extends unknown
> extends HttpClient<SecurityDataType> {
  ping = {
    /**
     * No description
     *
     * @tags ping
     * @name RequestPong
     * @summary Request Pong
     * @request GET:/ping/
     * @secure
     */
    requestPong: (params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/ping/`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  strategies = {
    /**
     * No description
     *
     * @tags strategies
     * @name GetPaginatedStrategies
     * @summary Get Paginated Strategies
     * @request GET:/strategies/
     * @secure
     */
    getPaginatedStrategies: (
      query?: {
        /** Assessment Id */
        assessment_id?: string;
        /** An enumeration. */
        priority?: PriorityEnum;
        /** An enumeration. */
        status?: StrategyStatusEnum;
        /** Cursor */
        cursor?: string;
        /**
         * Limit
         * @default 10
         */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedStrategyInfo, HTTPValidationError>({
        path: `/strategies/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name CreateStrategy
     * @summary Create Strategy
     * @request POST:/strategies/
     * @secure
     */
    createStrategy: (
      data: CreateStrategyRequestApi,
      params: RequestParams = {}
    ) =>
      this.request<StrategyResponseApi, HTTPValidationError>({
        path: `/strategies/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name GetStrategyById
     * @summary Get Strategy By Id
     * @request GET:/strategies/{strategy_id}
     * @secure
     */
    getStrategyById: (strategyId: string, params: RequestParams = {}) =>
      this.request<StrategyResponseDetailedApi, HTTPValidationError>({
        path: `/strategies/${strategyId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name DeleteStrategy
     * @summary Delete Strategy
     * @request DELETE:/strategies/{strategy_id}
     * @secure
     */
    deleteStrategy: (strategyId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/strategies/${strategyId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies
     * @name UpdateStrategy
     * @summary Update Strategy
     * @request PATCH:/strategies/{strategy_id}
     * @secure
     */
    updateStrategy: (
      strategyId: string,
      data: UpdateStrategyRequestApi,
      params: RequestParams = {}
    ) =>
      this.request<StrategyResponseDetailedApi, HTTPValidationError>({
        path: `/strategies/${strategyId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/assets
     * @name GetStrategiesForAnAsset
     * @summary Get Strategies For An Asset
     * @request GET:/strategies/assets/{asset_id}
     * @secure
     */
    getStrategiesForAnAsset: (
      assetId: string,
      query?: {
        /** Assessment Id */
        assessment_id?: string;
        /** An enumeration. */
        status?: StrategyStatusEnum;
        /** An enumeration. */
        priority?: PriorityEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<StrategyResponseApi[], HTTPValidationError>({
        path: `/strategies/assets/${assetId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/groups
     * @name GetStrategiesForAGroup
     * @summary Get Strategies For A Group
     * @request GET:/strategies/groups/{group_id}
     * @secure
     */
    getStrategiesForAGroup: (
      groupId: string,
      query?: {
        /** Assessment Id */
        assessment_id?: string;
        /** An enumeration. */
        status?: StrategyStatusEnum;
        /** An enumeration. */
        priority?: PriorityEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<StrategyResponseApi[], HTTPValidationError>({
        path: `/strategies/groups/${groupId}`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/assets
     * @name GetAssetsIdAssignedToAStrategy
     * @summary Get Assets Id Assigned To A Strategy
     * @request GET:/strategies/{strategy_id}/assets
     * @secure
     */
    getAssetsIdAssignedToAStrategy: (
      strategyId: string,
      params: RequestParams = {}
    ) =>
      this.request<StrategyObjectsIdResponseApi, HTTPValidationError>({
        path: `/strategies/${strategyId}/assets`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/groups
     * @name GetGroupsIdAssignedToAStrategy
     * @summary Get Groups Id Assigned To A Strategy
     * @request GET:/strategies/{strategy_id}/groups
     * @secure
     */
    getGroupsIdAssignedToAStrategy: (
      strategyId: string,
      params: RequestParams = {}
    ) =>
      this.request<StrategyObjectsIdResponseApi, HTTPValidationError>({
        path: `/strategies/${strategyId}/groups`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/recommendations
     * @name GetRecommendationsAssignedToAStrategy
     * @summary Get Recommendations Assigned To A Strategy
     * @request GET:/strategies/{strategy_id}/recommendations
     * @secure
     */
    getRecommendationsAssignedToAStrategy: (
      strategyId: string,
      params: RequestParams = {}
    ) =>
      this.request<RecommendationResponseApi[], HTTPValidationError>({
        path: `/strategies/${strategyId}/recommendations`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/assets
     * @name AssignAnAssetToAStrategy
     * @summary Assign An Asset To A Strategy
     * @request POST:/strategies/{strategy_id}/assets/{asset_id}/assign
     * @secure
     */
    assignAnAssetToAStrategy: (
      assetId: string,
      strategyId: string,
      params: RequestParams = {}
    ) =>
      this.request<StrategyAssignmentResponseApi, HTTPValidationError>({
        path: `/strategies/${strategyId}/assets/${assetId}/assign`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/groups
     * @name AssignAGroupToAStrategy
     * @summary Assign A Group To A Strategy
     * @request POST:/strategies/{strategy_id}/groups/{group_id}/assign
     * @secure
     */
    assignAGroupToAStrategy: (
      groupId: string,
      strategyId: string,
      params: RequestParams = {}
    ) =>
      this.request<StrategyAssignmentResponseApi, HTTPValidationError>({
        path: `/strategies/${strategyId}/groups/${groupId}/assign`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/recommendations
     * @name AssignARecommendationToAStrategy
     * @summary Assign A Recommendation To A Strategy
     * @request POST:/strategies/{strategy_id}/recommendations/{recommendation_id}/assign
     * @secure
     */
    assignARecommendationToAStrategy: (
      recommendationId: string,
      strategyId: string,
      params: RequestParams = {}
    ) =>
      this.request<StrategyAssignmentResponseApi, HTTPValidationError>({
        path: `/strategies/${strategyId}/recommendations/${recommendationId}/assign`,
        method: "POST",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/assets
     * @name DeleteAssetAssignmentFromAStrategy
     * @summary Delete Asset Assignment From A Strategy
     * @request DELETE:/strategies/{strategy_id}/assets/{asset_id}
     * @secure
     */
    deleteAssetAssignmentFromAStrategy: (
      strategyId: string,
      assetId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/strategies/${strategyId}/assets/${assetId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/groups
     * @name DeleteGroupAssignmentFromAStrategy
     * @summary Delete Group Assignment From A Strategy
     * @request DELETE:/strategies/{strategy_id}/groups/{group_id}
     * @secure
     */
    deleteGroupAssignmentFromAStrategy: (
      strategyId: string,
      groupId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/strategies/${strategyId}/groups/${groupId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/recommendations
     * @name DeleteRecommendationAssignmentFromAStrategy
     * @summary Delete Recommendation Assignment From A Strategy
     * @request DELETE:/strategies/{strategy_id}/recommendations/{recommendation_id}
     * @secure
     */
    deleteRecommendationAssignmentFromAStrategy: (
      strategyId: string,
      recommendationId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/strategies/${strategyId}/recommendations/${recommendationId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/comments
     * @name CreateComment
     * @summary Create Comment
     * @request POST:/strategies/{strategy_id}/comments/
     * @secure
     */
    createComment: (
      strategyId: string,
      data: CommentInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<CommentInfoResponse, HTTPValidationError>({
        path: `/strategies/${strategyId}/comments/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/comments
     * @name GetCommentById
     * @summary Get Comment By Id
     * @request GET:/strategies/{strategy_id}/comments/{comment_id}
     * @secure
     */
    getCommentById: (
      strategyId: string,
      commentId: string,
      params: RequestParams = {}
    ) =>
      this.request<CommentInfoResponse, HTTPValidationError>({
        path: `/strategies/${strategyId}/comments/${commentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/comments
     * @name DeleteCommentById
     * @summary Delete Comment By Id
     * @request DELETE:/strategies/{strategy_id}/comments/{comment_id}
     * @secure
     */
    deleteCommentById: (
      strategyId: string,
      commentId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/strategies/${strategyId}/comments/${commentId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/comments
     * @name UpdateComment
     * @summary Update Comment
     * @request PATCH:/strategies/{strategy_id}/comments/{comment_id}
     * @secure
     */
    updateComment: (
      strategyId: string,
      commentId: string,
      data: CommentInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<CommentInfoResponse, HTTPValidationError>({
        path: `/strategies/${strategyId}/comments/${commentId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/comments
     * @name GetComments
     * @summary Get Comments
     * @request GET:/strategies/{strategy_id}/comments
     * @secure
     */
    getComments: (strategyId: string, params: RequestParams = {}) =>
      this.request<CommentInfoResponse[], HTTPValidationError>({
        path: `/strategies/${strategyId}/comments`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/comments
     * @name DeleteComments
     * @summary Delete Comments
     * @request DELETE:/strategies/{strategy_id}/comments
     * @secure
     */
    deleteComments: (strategyId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/strategies/${strategyId}/comments`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/resources
     * @name GetResources
     * @summary Get Resources
     * @request GET:/strategies/{strategy_id}/resources
     * @secure
     */
    getResources: (strategyId: string, params: RequestParams = {}) =>
      this.request<ResourceInfo[], HTTPValidationError>({
        path: `/strategies/${strategyId}/resources`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/resources
     * @name UploadResource
     * @summary Upload Resource
     * @request POST:/strategies/{strategy_id}/resources
     * @secure
     */
    uploadResource: (
      strategyId: string,
      data: BodyUploadResourceStrategiesStrategyIdResourcesPost,
      query?: {
        /** Description */
        description?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/strategies/${strategyId}/resources`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/resources
     * @name DeleteResources
     * @summary Delete Resources
     * @request DELETE:/strategies/{strategy_id}/resources
     * @secure
     */
    deleteResources: (strategyId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/strategies/${strategyId}/resources`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/resources
     * @name GetResourceById
     * @summary Get Resource By Id
     * @request GET:/strategies/{strategy_id}/resources/{resource_id}
     * @secure
     */
    getResourceById: (
      strategyId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/strategies/${strategyId}/resources/${resourceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/resources
     * @name DeleteResourceById
     * @summary Delete Resource By Id
     * @request DELETE:/strategies/{strategy_id}/resources/{resource_id}
     * @secure
     */
    deleteResourceById: (
      strategyId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/strategies/${strategyId}/resources/${resourceId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/resources
     * @name UpdateResourceDescription
     * @summary Update Resource Description
     * @request PATCH:/strategies/{strategy_id}/resources/{resource_id}
     * @secure
     */
    updateResourceDescription: (
      strategyId: string,
      resourceId: string,
      query: {
        /** Description */
        description: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/strategies/${strategyId}/resources/${resourceId}`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags strategies/resources
     * @name DownloadResourceById
     * @summary Download Resource By Id
     * @request GET:/strategies/{strategy_id}/resources/{resource_id}/download
     * @secure
     */
    downloadResourceById: (
      strategyId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceDownloadInfo, HTTPValidationError>({
        path: `/strategies/${strategyId}/resources/${resourceId}/download`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /strategies/comments/resources
     * @name GetCommentResources
     * @summary Get Comment Resources
     * @request GET:/strategies/{strategy_id}/comments/{comment_id}/resources
     * @secure
     */
    getCommentResources: (
      commentId: string,
      strategyId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo[], HTTPValidationError>({
        path: `/strategies/${strategyId}/comments/${commentId}/resources`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /strategies/comments/resources
     * @name UploadCommentResource
     * @summary Upload Comment Resource
     * @request POST:/strategies/{strategy_id}/comments/{comment_id}/resources
     * @secure
     */
    uploadCommentResource: (
      commentId: string,
      strategyId: string,
      data: BodyUploadCommentResourceStrategiesStrategyIdCommentsCommentIdResourcesPost,
      query?: {
        /** Description */
        description?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/strategies/${strategyId}/comments/${commentId}/resources`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /strategies/comments/resources
     * @name DeleteCommentResources
     * @summary Delete Comment Resources
     * @request DELETE:/strategies/{strategy_id}/comments/{comment_id}/resources
     * @secure
     */
    deleteCommentResources: (
      commentId: string,
      strategyId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/strategies/${strategyId}/comments/${commentId}/resources`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /strategies/comments/resources
     * @name GetCommentResourceById
     * @summary Get Comment Resource By Id
     * @request GET:/strategies/{strategy_id}/comments/{comment_id}/resources/{resource_id}
     * @secure
     */
    getCommentResourceById: (
      commentId: string,
      strategyId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/strategies/${strategyId}/comments/${commentId}/resources/${resourceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /strategies/comments/resources
     * @name DeleteCommentResourceById
     * @summary Delete Comment Resource By Id
     * @request DELETE:/strategies/{strategy_id}/comments/{comment_id}/resources/{resource_id}
     * @secure
     */
    deleteCommentResourceById: (
      commentId: string,
      strategyId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/strategies/${strategyId}/comments/${commentId}/resources/${resourceId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /strategies/comments/resources
     * @name UpdateCommentResource
     * @summary Update Comment Resource
     * @request PATCH:/strategies/{strategy_id}/comments/{comment_id}/resources/{resource_id}
     * @secure
     */
    updateCommentResource: (
      commentId: string,
      strategyId: string,
      resourceId: string,
      query: {
        /** Description */
        description: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/strategies/${strategyId}/comments/${commentId}/resources/${resourceId}`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /strategies/comments/resources
     * @name DownloadCommentResourceById
     * @summary Download Comment Resource By Id
     * @request GET:/strategies/{strategy_id}/comments/{comment_id}/resources/{resource_id}/download
     * @secure
     */
    downloadCommentResourceById: (
      commentId: string,
      strategyId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceDownloadInfo, HTTPValidationError>({
        path: `/strategies/${strategyId}/comments/${commentId}/resources/${resourceId}/download`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  recommendations = {
    /**
     * No description
     *
     * @tags recommendations
     * @name GetRecommendationById
     * @summary Get Recommendation By Id
     * @request GET:/recommendations/{recommendation_id}
     * @secure
     */
    getRecommendationById: (
      recommendationId: string,
      params: RequestParams = {}
    ) =>
      this.request<RecommendationResponseApi, HTTPValidationError>({
        path: `/recommendations/${recommendationId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations
     * @name DeleteRecommendation
     * @summary Delete Recommendation
     * @request DELETE:/recommendations/{recommendation_id}
     * @secure
     */
    deleteRecommendation: (
      recommendationId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/recommendations/${recommendationId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations
     * @name UpdateRecommendation
     * @summary Update Recommendation
     * @request PATCH:/recommendations/{recommendation_id}
     * @secure
     */
    updateRecommendation: (
      recommendationId: string,
      data: UpdateRecommendationRequestApi,
      params: RequestParams = {}
    ) =>
      this.request<RecommendationResponseApi, HTTPValidationError>({
        path: `/recommendations/${recommendationId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations
     * @name GetPaginatedRecommendations
     * @summary Get Paginated Recommendations
     * @request GET:/recommendations/
     * @secure
     */
    getPaginatedRecommendations: (
      query?: {
        /** Assessment Id */
        assessment_id?: string;
        /** An enumeration. */
        status?: RecommendationStatusEnum;
        /** An enumeration. */
        type?: RecommendationTypesEnum;
        /** Subtype */
        subtype?: string;
        /** Cursor */
        cursor?: string;
        /**
         * Limit
         * @default 10
         */
        limit?: number;
      },
      params: RequestParams = {}
    ) =>
      this.request<PaginatedRecommendationInfo, HTTPValidationError>({
        path: `/recommendations/`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations
     * @name CreateRecommendation
     * @summary Create Recommendation
     * @request POST:/recommendations/
     * @secure
     */
    createRecommendation: (
      data: CreateRecommendationRequestApi,
      params: RequestParams = {}
    ) =>
      this.request<RecommendationResponseApi, HTTPValidationError>({
        path: `/recommendations/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations
     * @name GetStrategiesForARecommendation
     * @summary Get Strategies For A Recommendation
     * @request GET:/recommendations/{recommendation_id}/strategies
     * @secure
     */
    getStrategiesForARecommendation: (
      recommendationId: string,
      query?: {
        /** Assessment Id */
        assessment_id?: string;
        /** An enumeration. */
        status?: StrategyStatusEnum;
        /** An enumeration. */
        priority?: PriorityEnum;
      },
      params: RequestParams = {}
    ) =>
      this.request<StrategyResponseApi[], HTTPValidationError>({
        path: `/recommendations/${recommendationId}/strategies`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/comments
     * @name CreateComment
     * @summary Create Comment
     * @request POST:/recommendations/{recommendation_id}/comments/
     * @secure
     */
    createComment: (
      recommendationId: string,
      data: CommentInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<CommentInfoResponse, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/comments/`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/comments
     * @name GetCommentById
     * @summary Get Comment By Id
     * @request GET:/recommendations/{recommendation_id}/comments/{comment_id}
     * @secure
     */
    getCommentById: (
      recommendationId: string,
      commentId: string,
      params: RequestParams = {}
    ) =>
      this.request<CommentInfoResponse, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/comments/${commentId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/comments
     * @name DeleteCommentById
     * @summary Delete Comment By Id
     * @request DELETE:/recommendations/{recommendation_id}/comments/{comment_id}
     * @secure
     */
    deleteCommentById: (
      recommendationId: string,
      commentId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/comments/${commentId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/comments
     * @name UpdateComment
     * @summary Update Comment
     * @request PATCH:/recommendations/{recommendation_id}/comments/{comment_id}
     * @secure
     */
    updateComment: (
      recommendationId: string,
      commentId: string,
      data: CommentInfoRequest,
      params: RequestParams = {}
    ) =>
      this.request<CommentInfoResponse, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/comments/${commentId}`,
        method: "PATCH",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/comments
     * @name GetComments
     * @summary Get Comments
     * @request GET:/recommendations/{recommendation_id}/comments
     * @secure
     */
    getComments: (recommendationId: string, params: RequestParams = {}) =>
      this.request<CommentInfoResponse[], HTTPValidationError>({
        path: `/recommendations/${recommendationId}/comments`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/comments
     * @name DeleteComments
     * @summary Delete Comments
     * @request DELETE:/recommendations/{recommendation_id}/comments
     * @secure
     */
    deleteComments: (recommendationId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/comments`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/resources
     * @name GetResources
     * @summary Get Resources
     * @request GET:/recommendations/{recommendation_id}/resources
     * @secure
     */
    getResources: (recommendationId: string, params: RequestParams = {}) =>
      this.request<ResourceInfo[], HTTPValidationError>({
        path: `/recommendations/${recommendationId}/resources`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/resources
     * @name UploadResource
     * @summary Upload Resource
     * @request POST:/recommendations/{recommendation_id}/resources
     * @secure
     */
    uploadResource: (
      recommendationId: string,
      data: BodyUploadResourceRecommendationsRecommendationIdResourcesPost,
      query?: {
        /** Description */
        description?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/resources`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/resources
     * @name DeleteResources
     * @summary Delete Resources
     * @request DELETE:/recommendations/{recommendation_id}/resources
     * @secure
     */
    deleteResources: (recommendationId: string, params: RequestParams = {}) =>
      this.request<any, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/resources`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/resources
     * @name GetResourceById
     * @summary Get Resource By Id
     * @request GET:/recommendations/{recommendation_id}/resources/{resource_id}
     * @secure
     */
    getResourceById: (
      recommendationId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/resources/${resourceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/resources
     * @name DeleteResourceById
     * @summary Delete Resource By Id
     * @request DELETE:/recommendations/{recommendation_id}/resources/{resource_id}
     * @secure
     */
    deleteResourceById: (
      recommendationId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/resources/${resourceId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/resources
     * @name UpdateResourceDescription
     * @summary Update Resource Description
     * @request PATCH:/recommendations/{recommendation_id}/resources/{resource_id}
     * @secure
     */
    updateResourceDescription: (
      recommendationId: string,
      resourceId: string,
      query: {
        /** Description */
        description: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/resources/${resourceId}`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags recommendations/resources
     * @name DownloadResourceById
     * @summary Download Resource By Id
     * @request GET:/recommendations/{recommendation_id}/resources/{resource_id}/download
     * @secure
     */
    downloadResourceById: (
      recommendationId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceDownloadInfo, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/resources/${resourceId}/download`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /recommendations/comments/resources
     * @name GetCommentResources
     * @summary Get Comment Resources
     * @request GET:/recommendations/{recommendation_id}/comments/{comment_id}/resources
     * @secure
     */
    getCommentResources: (
      commentId: string,
      recommendationId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo[], HTTPValidationError>({
        path: `/recommendations/${recommendationId}/comments/${commentId}/resources`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /recommendations/comments/resources
     * @name UploadCommentResource
     * @summary Upload Comment Resource
     * @request POST:/recommendations/{recommendation_id}/comments/{comment_id}/resources
     * @secure
     */
    uploadCommentResource: (
      commentId: string,
      recommendationId: string,
      data: BodyUploadCommentResourceRecommendationsRecommendationIdCommentsCommentIdResourcesPost,
      query?: {
        /** Description */
        description?: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/comments/${commentId}/resources`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.FormData,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /recommendations/comments/resources
     * @name GetCommentResourceById
     * @summary Get Comment Resource By Id
     * @request GET:/recommendations/{recommendation_id}/comments/{comment_id}/resources/{resource_id}
     * @secure
     */
    getCommentResourceById: (
      commentId: string,
      recommendationId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/comments/${commentId}/resources/${resourceId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /recommendations/comments/resources
     * @name UpdateCommentResource
     * @summary Update Comment Resource
     * @request PATCH:/recommendations/{recommendation_id}/comments/{comment_id}/resources/{resource_id}
     * @secure
     */
    updateCommentResource: (
      commentId: string,
      recommendationId: string,
      resourceId: string,
      query: {
        /** Description */
        description: string;
      },
      params: RequestParams = {}
    ) =>
      this.request<ResourceInfo, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/comments/${commentId}/resources/${resourceId}`,
        method: "PATCH",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /recommendations/comments/resources
     * @name DownloadCommentResourceById
     * @summary Download Comment Resource By Id
     * @request GET:/recommendations/{recommendation_id}/comments/{comment_id}/resources/{resource_id}/download
     * @secure
     */
    downloadCommentResourceById: (
      commentId: string,
      recommendationId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<ResourceDownloadInfo, HTTPValidationError>({
        path: `/recommendations/${recommendationId}/comments/${commentId}/resources/${resourceId}/download`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /recommendations/comments/resources
     * @name DeleteCommentResourceById
     * @summary Delete Comment Resource By Id
     * @request DELETE:/recommendations/recommendations/{recommendation_id}/comments/{comment_id}/resources/{resource_id}
     * @secure
     */
    deleteCommentResourceById: (
      commentId: string,
      recommendationId: string,
      resourceId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/recommendations/recommendations/${recommendationId}/comments/${commentId}/resources/${resourceId}`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags /recommendations/comments/resources
     * @name DeleteCommentResources
     * @summary Delete Comment Resources
     * @request DELETE:/recommendations/recommendations/{recommendation_id}/comments/{comment_id}/resources
     * @secure
     */
    deleteCommentResources: (
      commentId: string,
      recommendationId: string,
      params: RequestParams = {}
    ) =>
      this.request<any, HTTPValidationError>({
        path: `/recommendations/recommendations/${recommendationId}/comments/${commentId}/resources`,
        method: "DELETE",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}

import type { ApplicationConfig } from ".";
import { baseConfig } from "./base.config";

export const testConfig: ApplicationConfig = {
  ...baseConfig,
  featureFlags: {
    ...baseConfig.featureFlags,
  },
  organization: {
    ...baseConfig.organization,
    demoId: "0ab4aaa8-ffe3-4c3e-87b7-8c4b1ad50cd4", // ACME Corp
    testId: "c82630f8-fe8e-4104-b05e-103f1c247cdd", // Automated Company
  },
  documentation: {
    ...baseConfig.documentation,
    url: "https://docs.test.iris.arup.com/",
  },
  user: {
    // somebody messed up the cognito provider for test but we can't change it
    provider: "azure-arup-ad",
  },
};

import { Auth } from "aws-amplify";
import useSWR, { mutate } from "swr";

import type {
  AssetStatusEnum,
  BuildingUseEnum,
} from "@/apis/services/AssetService";
import type {
  AdminUpdateUserAttributesRequestModel,
  CreateOrganizationRequestModel,
  CreateOrganizationResponseModel,
  CreateUserRequestModel,
  UpdateOrganizationSettingsRequest,
  UserRoleEnum,
} from "@/apis/services/OrganizationService";
import { FIVE_MINUTES, TWO_MINUTES } from "@/constants/session";
import { clearCache } from "@/hooks/useUserInfo";
import { ALL_ORGANIZATIONS } from "@/utils/organization/organization-utils";

import { useServices } from "./useServices";

export const useAllOrganizations = (isAdmin: boolean, addAll?: boolean) => {
  const { client } = useServices();
  const { data, error } = useSWR(
    isAdmin ? "useAllOrganizations" : null,
    async () => {
      const orgs = await client.organization.getPaginatedOrganization({
        limit: 250,
      });
      return orgs.data.results.sort((a, b) => a.name.localeCompare(b.name));
    },
    { dedupingInterval: FIVE_MINUTES }
  );

  const isLoading = !error && !data;
  const isError = error;

  if (addAll) {
    const allOrgs: CreateOrganizationResponseModel = {
      id: ALL_ORGANIZATIONS,
      name: "All Organizations",
      logo: "",
      description:
        "This view represents settings that can be applied to all organizations in the environment.",
    };
    const _data = [allOrgs].concat(data ?? []);

    return {
      data: _data,
      isLoading: isLoading,
      isError: isError,
    };
  }

  return {
    data: data || [],
    isLoading: isLoading,
    isError: isError,
  };
};

export const useUpdateOrganization = () => {
  const { client } = useServices();
  const handleUpdateOrganization = async (
    orgId: string,
    data: CreateOrganizationRequestModel
  ) => {
    await client.organization.updateOrganization(orgId, data);
    mutate("useAllOrganizations");
    mutate("/api/me");
  };
  return handleUpdateOrganization;
};

export const useUpdateOrganizationForUser = () => {
  const { client } = useServices();
  const handleUpdateOrganizationForUser = async (
    orgId: string,
    username: string
  ) => {
    await client.organization.assignUserToOrganization(orgId, username);
    await clearCache(true);

    // Forces our client to refresh their token
    await Auth.currentAuthenticatedUser({ bypassCache: true });

    await mutate("useAllOrganizations");
    await mutate("/api/me");
  };
  return handleUpdateOrganizationForUser;
};

export interface ViewerQuery {
  statuses?: AssetStatusEnum[];
  primary_uses?: BuildingUseEnum[] | null;
}

export const usePortfolioViewer = (orgId?: string, query?: ViewerQuery) => {
  const { asset } = useServices();
  const { data, isLoading, error } = useSWR(
    orgId ? ["usePortfolioViewer", orgId, query] : undefined,
    async () => {
      try {
        const response = await asset.portfolioViewer.getDetailPortfolio(query);
        return response.data;
      } catch (error) {
        console.error("usePortfolioViewer failed: ", error);
      }
    }
  );

  return {
    data: data,
    isLoading: isLoading,
    isError: error,
  };
};

export const useCreateOrganization = () => {
  const { client } = useServices();
  const handleCreateOrganization = async (
    data: CreateOrganizationRequestModel
  ) => {
    const response = await client.organization.createOrganization(data);
    mutate("useAllOrganizations");
    return response.data;
  };
  return handleCreateOrganization;
};

export const useGetOrganizationUsers = (orgId?: string) => {
  const { client } = useServices();
  const { data, error } = useSWR(
    orgId ? ["useGetOrganizationUsers", orgId] : null,
    async () => {
      const users = await client.organization.getOrganizationUsers(orgId!);
      return users.data;
    }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useUpdateUserRole = () => {
  const { client } = useServices();
  const handleUpdateUserRole = async (
    username: string,
    userRole: UserRoleEnum,
    organizationId: string
  ) => {
    await client.user.updateUserRole(username, { user_role: userRole });
    mutate(["useGetOrganizationUsers", organizationId]);
  };
  return handleUpdateUserRole;
};

export const useUpdateMyRoleToGuest = () => {
  const { client } = useServices();
  const handleUpdateMyRoleToGuest = async () => {
    await client.user.updateMyRoleToGuest();
    // Forces our client to refresh their token
    await Auth.currentAuthenticatedUser({ bypassCache: true });
  };
  return handleUpdateMyRoleToGuest;
};

export const useCreateUsers = () => {
  const { client } = useServices();
  const handleCreateUser = async (
    userModal: CreateUserRequestModel,
    organizationId: string
  ) => {
    const response = await client.user.createUser(userModal);
    mutate(["useGetOrganizationUsers", organizationId]);
    return response.data;
  };
  return handleCreateUser;
};

export const useUpdateUser = () => {
  const { client } = useServices();
  const handleUpdateUser = async (
    username: string,
    data: AdminUpdateUserAttributesRequestModel,
    organizationId: string
  ) => {
    await client.user.updateUserAttributes(username, data);
    mutate(["useGetOrganizationUsers", organizationId]);
  };
  return handleUpdateUser;
};

export const useDeleteUsers = () => {
  const { client } = useServices();
  const handleDeleteUsers = async (username: string) => {
    await client.user.deleteUser(username);
  };
  return handleDeleteUsers;
};

export const useGetOrgSettings = (id?: string) => {
  const { client } = useServices();
  const { data, error } = useSWR(
    id ? ["useGetOrgSettings", id] : null,
    async () => {
      const settings = await client.organization.getOrganizationSettings(id!);
      return settings.data;
    },
    { dedupingInterval: TWO_MINUTES }
  );

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const useUpdateOrganizationSettings = () => {
  const { client } = useServices();
  const updateOrganizationSettings = async (
    orgId: string,
    data: UpdateOrganizationSettingsRequest
  ) => {
    await client.organization.updateOrganizationSettings(orgId!, data);
    mutate(["useGetOrgSettings", orgId!]);
  };
  return updateOrganizationSettings;
};

export const useCreateDefaultOrgSetting = () => {
  const { client } = useServices();
  const handleCreateDefaultOrgSettings = async (organizationId: string) => {
    await client.organization.createDefaultOrganizationSettings(organizationId);
  };
  return handleCreateDefaultOrgSettings;
};

import type { ApplicationConfig } from ".";
import { baseConfig } from "./base.config";

export const devConfig: ApplicationConfig = {
  ...baseConfig,
  featureFlags: {
    ...baseConfig.featureFlags,
  },
  organization: {
    ...baseConfig.organization,
    demoId: "d7f233a3-3d2d-461c-a75b-b1ddb083d28a", // Slalom
    testId: "79046760-ced7-45f7-a0e4-4bf692d5e4c8", // Automated Company
  },
  documentation: {
    ...baseConfig.documentation,
    url: "https://docs.dev.iris.arup.com/",
  },
};
